import logger from "../../../../lib/logger";
import { mapPrice } from "./mapPrice";

const isValid = product => !!product.name || !!mapPrice(product.price);

export const filterInvalidProducts = (products = []) =>
  products.filter(product => {
    if (!isValid(product)) {
      const errorText = "Invalid product data from Search API";
      const message = product?.id
        ? `${errorText}, product id: ${product.id}`
        : errorText;

      logger.warn(new Error(message));

      return false;
    }

    return true;
  });
