import {
  ANALYTICS_REFINEMENTS_TOGGLED,
  ANALYTICS_REFINEMENTS_SELECT,
  ANALYTICS_CLEAR_REFINEMENT,
  ANALYTICS_CLEAR_ALL_REFINEMENTS,
  ANALYTICS_SELECT_ALL,
  ANALYTICS_SMALL_VIEW_SUBMIT,
  ANALYTICS_REFINEMENTS_SORT
} from "../constants";
import { getPendingAnalyticsEvents } from "../selectors";
import { analyticsRemovePendingEvent } from "./analyticsPending";
import {
  analyticsRefinementsSelect,
  analyticsSelectAll,
  analyticsRefinementsSort,
  analyticsClearRefinement,
  analyticsClearAllRefinements,
  analyticsRefinementsToggled,
  analyticsSmallViewSubmit
} from "./analyticsRefinements";

const actionCreatorMap = {
  [ANALYTICS_REFINEMENTS_TOGGLED]: analyticsRefinementsToggled,
  [ANALYTICS_REFINEMENTS_SELECT]: analyticsRefinementsSelect,
  [ANALYTICS_SELECT_ALL]: analyticsSelectAll,
  [ANALYTICS_CLEAR_REFINEMENT]: analyticsClearRefinement,
  [ANALYTICS_CLEAR_ALL_REFINEMENTS]: analyticsClearAllRefinements,
  [ANALYTICS_SMALL_VIEW_SUBMIT]: analyticsSmallViewSubmit,
  [ANALYTICS_REFINEMENTS_SORT]: analyticsRefinementsSort
};

const getActionCreator = actionCreatorId => actionCreatorMap[actionCreatorId];

const applyAndRemovePendingEvents = () => (dispatch, getState) => {
  const {
    refinementPendingActionType,
    refinementPendingPayload
  } = getPendingAnalyticsEvents(getState());

  const actionCreator = getActionCreator(refinementPendingActionType);

  if (actionCreator) dispatch(actionCreator(refinementPendingPayload));

  dispatch(analyticsRemovePendingEvent());
};

export { applyAndRemovePendingEvents as default };
