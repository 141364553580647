import { TILE_SIZES } from "../constants";
const {
  TILE_SIZE_ONE_BY_ONE,
  TILE_SIZE_TWO_BY_ONE,
  TILE_SIZE_TWO_BY_TWO
} = TILE_SIZES;

const getTileSize = tileTreatment => {
  if (!tileTreatment) {
    return TILE_SIZE_ONE_BY_ONE;
  }

  const { widthMultiplier, heightMultiplier } = tileTreatment;

  if (widthMultiplier !== 2) {
    return TILE_SIZE_ONE_BY_ONE;
  }

  return heightMultiplier === 2 ? TILE_SIZE_TWO_BY_TWO : TILE_SIZE_TWO_BY_ONE;
};

export default getTileSize;
