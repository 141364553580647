import styles from "./index.css";
import PropTypes from "prop-types";
import { withTranslation } from "../../translation";
import { ProgressIndicator } from "@asosteam/asos-web-component-library-progress-indicator";

const ResultsProgressOverlay = ({ formatTranslation }) => (
  <>
    <span className={styles.screenReaderOnly} role="alert" id="alert">
      {formatTranslation("generic_accessibility_loading_products")}
    </span>
    <div className={styles.resultsProgressOverlay}>
      <span className={styles.mask} />
      <ProgressIndicator transparent={false} />
    </div>
  </>
);

ResultsProgressOverlay.propTypes = {
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(ResultsProgressOverlay);
