import getCategoryDetailsBreadcrumb from "./getCategoryDetailsBreadcrumb";

export default state => {
  const breadcrumbs = state.category.breadcrumbs;

  if (breadcrumbs) {
    const combinedBreadcrumbs = [
      ...breadcrumbs,
      getCategoryDetailsBreadcrumb(state)
    ].filter(Boolean);

    const duplicatesRemoved = combinedBreadcrumbs.filter(
      (value, index, self) =>
        index === self.findIndex(item => item.Label === value.Label)
    );

    const { Label, CategoryId } = duplicatesRemoved.pop();
    const finalBreadcrumbWithUrlRemoved = { Label, CategoryId };

    return [...duplicatesRemoved, finalBreadcrumbWithUrlRemoved];
  } else {
    return [];
  }
};
