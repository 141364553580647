export const categoryHasStockButPageDoesNot = ({
  itemCount,
  productsLength
}) => {
  if (itemCount > 0 && productsLength === 0) {
    return true;
  }

  return false;
};
