import {
  MIX_MATCH,
  MIX_MATCH_GROUP,
  MIX_MATCH_PRODUCT,
  MORE_COLOURS,
  MORE_COLOURS_FACET
} from "../../constants";

export const mapBadgeType = ({
  hasMoreColours,
  hasVariantColours,
  productType
}) => {
  if (productType === MIX_MATCH_GROUP || productType === MIX_MATCH_PRODUCT) {
    return MIX_MATCH;
  } else if (hasVariantColours) {
    return MORE_COLOURS;
  } else if (hasMoreColours) {
    return MORE_COLOURS_FACET;
  }

  return null;
};
