import { mapProduct } from "./mapProduct";
import { insertIntoArray } from "./insertIntoArray";
import { setTileIds } from "./setTileIds";

export const mapAndInsertProducts = ({
  existingProducts,
  products,
  insertionIndex
}) => {
  let newProducts = products.map(product => mapProduct({ product }));

  newProducts = insertIntoArray({
    existingProducts,
    newProducts,
    insertionIndex
  });
  setTileIds(newProducts);

  return { products: newProducts };
};
