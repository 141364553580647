import getClientConfigValue from "./getClientConfigValue";
import constants from "../../webpack/constants";

const {
  paths: { public: webpackPublicPath }
} = constants;

__webpack_public_path__ = `${getClientConfigValue(
  "ASSETS_BASE_PATH"
)}${webpackPublicPath}`;
