import categories from "./categories";
import { getCategoryId } from "../../../../../state/modules/category";

const getCarouselCategory = state => {
  const currentCid = getCategoryId(state);

  return categories[currentCid];
};

export default getCarouselCategory;
