import getTreatmentDefinition from "./getTreatmentDefinition";
import getTileSize from "./getTileSize";
import { TILE_SIZE_ONE_BY_ONE } from "../constants";
import getTreatmentTypeFromHeroMedia from "./getTreatmentTypeFromHeroMedia";
import getUsableHeroMedia from "./getUsableHeroMedia";
import { getAllAppliedRefinements } from "../../state/modules/search/selectors";

const getProductTreatment = ({ product, productIndex }, state) => {
  const refinements = getAllAppliedRefinements(state);

  if (refinements.length) {
    return null;
  }

  const productTreatmentDefinition = getTreatmentDefinition(
    productIndex,
    state
  );

  if (!productTreatmentDefinition) {
    return null;
  }

  const tileSize = getTileSize(productTreatmentDefinition);

  if (tileSize === TILE_SIZE_ONE_BY_ONE) {
    return null;
  }

  const usableHeroMedia = getUsableHeroMedia({
    product,
    productTreatmentDefinition,
    tileSize
  });

  const treatmentType = getTreatmentTypeFromHeroMedia(
    usableHeroMedia,
    tileSize
  );

  return {
    tileSize,
    heroMedia: usableHeroMedia,
    treatmentType
  };
};

export default getProductTreatment;
