import { isLargeMediumViewPort } from "../viewportHelper";
import { changeMultiFacet } from "../actions";
import applyFacets from "./apply";
import {
  analyticsSelectAll,
  analyticsAddPendingSelectAll
} from "../../analytics/actions";

const getFacetById = (state, facetId) => {
  const {
    search: { facets }
  } = state;

  return facets.find(facet => facet.id === facetId);
};

const getSelectedFacetValues = ({ facetValues }, facetId) =>
  facetValues.reduce((acc, facetValue) => {
    if (!facetValue.count) {
      return acc;
    }

    acc.push({
      facetId,
      valueId: facetValue.id,
      isSelected: true
    });

    return acc;
  }, []);

export default function selectAll(facetId) {
  return (dispatch, getState) => {
    const state = getState();
    const facet = getFacetById(state, facetId);

    if (facet) {
      dispatch(changeMultiFacet(getSelectedFacetValues(facet, facetId)));

      if (isLargeMediumViewPort(state)) {
        dispatch(applyFacets());
        dispatch(analyticsAddPendingSelectAll(facet));
      } else {
        dispatch(analyticsSelectAll(facet));
      }
    }
  };
}
