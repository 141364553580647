import getCategoryDetails from "./getCategoryDetails";
import logger from "../../../../lib/logger";
import { getLocale } from "../../config/selectors";

export default state => {
  const { id, canonicalUrls } = getCategoryDetails(state);
  const locale = getLocale(state);

  if (!canonicalUrls) {
    logger.error(
      new Error(`canonicalUrls was not of expected type for cid: ${id}`)
    );

    return "";
  }

  let url = "";
  if (typeof canonicalUrls[locale] === "string") {
    url = canonicalUrls[locale];
  } else if (typeof canonicalUrls["x-default"] === "string") {
    url = canonicalUrls["x-default"];
  }

  return url;
};
