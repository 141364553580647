import { getLocation } from "../state/modules/router/selectors";
import {
  getAllAppliedRefinements,
  getRecommendationsAnalytics
} from "../state/modules/search/selectors";
import {
  isFacetPriceRange,
  getNextPriceRange,
  getPrevPriceRange
} from "../state/modules/search/priceRangeSelectors";
import { getSearchTerm } from "../state/modules/facet/selectors";
import {
  TRACKING_KEY_SAVE_FOR_LATER,
  TRACKING_CONTEXT_SAVE_FOR_LATER,
  TRACKING_KEY_PRODUCT_CLICK,
  TRACKING_CONTEXT_PRODUCT_CLICK
} from "./constants";
import {
  getClientPageLoadData,
  getPageSpecificAnalyticsData,
  getAppliedRefinementsData,
  getSharedAnalyticsObject
} from "./pageLoad";
import { getCurrencyCode } from "../state/modules/config/selectors";

import {
  ANALYTICS_CLEAR_REFINEMENT,
  ANALYTICS_CLEAR_ALL_REFINEMENTS,
  ANALYTICS_LOAD_MORE,
  ANALYTICS_LOAD_PREVIOUS,
  ANALYTICS_OPEN_REFINEMENTS_SMALL,
  ANALYTICS_OPEN_REFINEMENT_TYPE,
  ANALYTICS_SELECT_ALL,
  ANALYTICS_REFINEMENTS_SELECT,
  ANALYTICS_REFINEMENTS_DESELECT,
  ANALYTICS_SAVE_FOR_LATER,
  ANALYTICS_PRODUCT_CLICK,
  ANALYTICS_PAGE_LOAD,
  ANALYTICS_REFINEMENTS_DONE,
  ANALYTICS_REFINEMENTS_SORT,
  ANALYTICS_REFINEMENTS_VIEW_ITEMS,
  ANALYTICS_REFINEMENTS_CLOSE_MOBILE,
  ANALYTICS_CLOSE_REFINEMENT_TYPE
} from "../state/modules/analytics/constants";

import { isLargeMediumViewPort } from "../state/modules/search/viewportHelper";
import getCustomerId from "../client/identity/getCustomerId";
import getProductTreatment from "../helpers/getProductTreatment";

const emitterEvents = {};

const buildRecsAnalytics = state => {
  const {
    personalisationStatus,
    numberOfItems: numberOfRecommendedItems
  } = getRecommendationsAnalytics(state);

  return {
    personalisationStatus,
    numberOfRecommendedItems
  };
};

const buildPaginationEvent = ({ state, pageNumber, returnedProductList }) => {
  const { query } = getLocation(state);

  return [
    "tracking.productList.paginate",
    {
      pageNumber,
      context:
        query.refine || query.sort ? "paginate with refinement" : "paginate",
      returnedProductList,
      refinements: {
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      },
      ...getSharedAnalyticsObject(state),
      ...buildRecsAnalytics(state)
    }
  ];
};

const buildRefinementEvent = (customEventPayload, actionPayload) => {
  const eventType = "tracking.productList.refine.refinements";
  const { returnedProductList, totalStyles } = actionPayload;

  const commonPayload = { returnedProductList, totalStyles };
  const eventPayload = { ...commonPayload, ...customEventPayload };

  return [eventType, eventPayload];
};

export const buildAppliedRefinement = (
  state,
  facet,
  facetValues,
  actionType
) => {
  const appliedRefinement = {
    refinementId: facet.id,
    refinementName: facet.name
  };
  if (isFacetPriceRange(facet)) {
    const actionsUsingPrevPriceRange = [ANALYTICS_CLEAR_ALL_REFINEMENTS];
    if (isLargeMediumViewPort(state)) {
      actionsUsingPrevPriceRange.push(ANALYTICS_CLEAR_REFINEMENT);
    }
    const {
      leftIndicator: min,
      rightIndicator: max
    } = actionsUsingPrevPriceRange.includes(actionType)
      ? getPrevPriceRange(state)
      : getNextPriceRange(state);

    return { ...appliedRefinement, min, max };
  } else {
    return {
      ...appliedRefinement,
      selections: facetValues.map(facetValue => ({
        selectionId: facetValue.id,
        selectionName: facetValue.name
      }))
    };
  }
};

const buildSaveForLaterEvent = ({
  productId,
  reducedPrice,
  price,
  ...rest
}) => [
  TRACKING_KEY_SAVE_FOR_LATER,
  {
    context: TRACKING_CONTEXT_SAVE_FOR_LATER,
    productIID: productId,
    priceLocalCurrency: reducedPrice || price,
    ...rest
  }
];

const buildProductClickEvent = ({
  carouselCatId,
  advertId,
  customerId,
  featuredProductTileType
}) => [
  TRACKING_KEY_PRODUCT_CLICK,
  {
    context: TRACKING_CONTEXT_PRODUCT_CLICK,
    carouselCatId,
    advertId,
    customerId,
    featuredProductTileType
  }
];

emitterEvents[ANALYTICS_LOAD_MORE] = (action, state) => {
  const {
    payload: { returnedProductList, page }
  } = action;

  return buildPaginationEvent({
    state,
    pageNumber: page,
    returnedProductList
  });
};

emitterEvents[ANALYTICS_LOAD_PREVIOUS] = (action, state) => {
  const {
    payload: { returnedProductList, page }
  } = action;

  return buildPaginationEvent({
    state,
    pageNumber: page,
    returnedProductList
  });
};

emitterEvents[ANALYTICS_OPEN_REFINEMENTS_SMALL] = (action, state) =>
  buildRefinementEvent(
    {
      refinements: {
        context: "refinements:open",
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      }
    },
    action.payload
  );

emitterEvents[ANALYTICS_CLEAR_REFINEMENT] = (action, state) => {
  const { facet, clearedFacetValues } = action.payload;

  return buildRefinementEvent(
    {
      refinements: {
        context: "refinements:clear",
        listAppliedRefinements: [
          buildAppliedRefinement(state, facet, clearedFacetValues, action.type)
        ],
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      },
      ...buildRecsAnalytics(state),
      ...getSharedAnalyticsObject(state)
    },
    action.payload
  );
};

emitterEvents[ANALYTICS_REFINEMENTS_DONE] = (action, state) => {
  const { facet, selectedFacetValues } = action.payload;

  return buildRefinementEvent(
    {
      refinements: {
        context: "refinements:done",
        listAppliedRefinements: [
          buildAppliedRefinement(state, facet, selectedFacetValues)
        ],
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      },
      ...buildRecsAnalytics(state),
      ...getSharedAnalyticsObject(state)
    },
    action.payload
  );
};

emitterEvents[ANALYTICS_REFINEMENTS_VIEW_ITEMS] = (action, state) => {
  const { facet, selectedFacetValues } = action.payload;

  return buildRefinementEvent(
    {
      refinements: {
        context: "refinements:close:viewItems",
        listAppliedRefinements: facet
          ? [buildAppliedRefinement(state, facet, selectedFacetValues)]
          : [],
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      },
      ...buildRecsAnalytics(state),
      ...getSharedAnalyticsObject(state)
    },
    action.payload
  );
};

emitterEvents[ANALYTICS_REFINEMENTS_CLOSE_MOBILE] = (action, state) =>
  buildRefinementEvent(
    {
      refinements: {
        context: "refinements:close:non-panel",
        listAppliedRefinements: [],
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      }
    },
    action.payload
  );

emitterEvents[ANALYTICS_SELECT_ALL] = (action, state) => {
  const facet = action.payload;

  const selectedFacetValues = facet.facetValues.filter(
    facetValue => facetValue.count > 0
  );

  return buildRefinementEvent(
    {
      refinements: {
        context: "refinements:select:all",
        listAppliedRefinements: [
          buildAppliedRefinement(state, facet, selectedFacetValues)
        ],
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      },
      ...buildRecsAnalytics(state),
      ...getSharedAnalyticsObject(state)
    },
    action.payload
  );
};

emitterEvents[ANALYTICS_CLEAR_ALL_REFINEMENTS] = (action, state) => {
  const { facets } = action.payload;

  const facetsWithSelectedValues = facets.filter(
    facet => facet.hasSelectedValues
  );

  return buildRefinementEvent(
    {
      refinements: {
        context: "refinements:clear:all",
        listAppliedRefinements: facetsWithSelectedValues.map(facet =>
          buildAppliedRefinement(
            state,
            facet,
            facet.facetValues.filter(facetValue => facetValue.isSelected),
            action.type
          )
        ),
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      },
      ...buildRecsAnalytics(state),
      ...getSharedAnalyticsObject(state)
    },
    action.payload
  );
};

emitterEvents[ANALYTICS_OPEN_REFINEMENT_TYPE] = (action, state) =>
  buildRefinementEvent(
    {
      refinements: {
        context: "refinements:open:refinementType",
        listAppliedRefinements: [
          {
            refinementId: action.payload.id,
            refinementName: action.payload.name
          }
        ],
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      }
    },
    action.payload
  );

emitterEvents[ANALYTICS_CLOSE_REFINEMENT_TYPE] = (action, state) =>
  buildRefinementEvent(
    {
      refinements: {
        context: "refinements:close",
        listAppliedRefinements: [
          {
            refinementId: action.payload.id,
            refinementName: action.payload.name
          }
        ],
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      }
    },
    action.payload
  );

emitterEvents[ANALYTICS_REFINEMENTS_SORT] = (action, state) =>
  buildRefinementEvent(
    {
      refinements: {
        context: "refinements:sort",
        sort: {
          sortType: action.payload.type,
          sortName: action.payload.name
        },
        listAllAppliedRefinements: getAllAppliedRefinements(state)
      },
      ...buildRecsAnalytics(state),
      ...getSharedAnalyticsObject(state)
    },
    action.payload
  );

emitterEvents[ANALYTICS_REFINEMENTS_SELECT] = (action, state) => {
  const { facet, facetValue } = action.payload;

  return buildRefinementEvent(
    {
      refinements: {
        context: "refinements:select",
        listAppliedRefinements: [
          buildAppliedRefinement(state, facet, [facetValue])
        ],
        listAllAppliedRefinements: getAllAppliedRefinements(state),
        filtered: !!getSearchTerm(state)
      },
      ...buildRecsAnalytics(state),
      ...getSharedAnalyticsObject(state)
    },
    action.payload
  );
};

emitterEvents[ANALYTICS_REFINEMENTS_DESELECT] = (action, state) => {
  const { facet, facetValue } = action.payload;

  return buildRefinementEvent(
    {
      refinements: {
        context: "refinements:deselect",
        listAppliedRefinements: [
          buildAppliedRefinement(state, facet, [facetValue])
        ],
        listAllAppliedRefinements: getAllAppliedRefinements(state),
        filtered: !!getSearchTerm(state)
      },
      ...buildRecsAnalytics(state),
      ...getSharedAnalyticsObject(state)
    },
    action.payload
  );
};

emitterEvents[ANALYTICS_SAVE_FOR_LATER] = (action, state) => {
  const currencyCode = getCurrencyCode(state);

  return buildSaveForLaterEvent({ ...action.payload, currencyCode });
};

emitterEvents[ANALYTICS_PRODUCT_CLICK] = async (action, state) => {
  const {
    carouselCatId,
    advertId,
    product,
    sendTreatmentAnalytics
  } = action.payload;

  const treatment = getProductTreatment(
    { product, productIndex: product.index },
    state
  );
  const customerId = (await getCustomerId()) ?? -1;

  return buildProductClickEvent({
    carouselCatId,
    advertId,
    customerId,
    featuredProductTileType: sendTreatmentAnalytics
      ? treatment?.treatmentType
      : null
  });
};

emitterEvents[ANALYTICS_PAGE_LOAD] = async (_, state) => [
  "tracking.productList.pageLoad",
  Object.assign(
    {},
    await getClientPageLoadData(state),
    getPageSpecificAnalyticsData(state),
    await getAppliedRefinementsData(state)
  )
];

export default emitterEvents;
