import { ALL_LOG_LEVELS } from "./constants";

const maxLogLeveIndex = process.env.MAX_LOG_LEVEL
  ? ALL_LOG_LEVELS.indexOf(process.env.MAX_LOG_LEVEL)
  : 0;
export const logger = ALL_LOG_LEVELS.reduce(
  (logger, level, index) => ({
    ...logger,
    [level]: error => {
      if (index > maxLogLeveIndex) {
        return;
      }
      console[level](...[new Date().toString(), error]); // eslint-disable-line no-console
      window.NREUM?.noticeError(error);
    }
  }),
  {}
);
