import { removeScrollToQueryRegex } from "../helpers/removeScrollToQuery/constants";

const isPersisted = event => event.persisted;

const scrollToElement = element => {
  if (!element) {
    return;
  }

  setTimeout(() => {
    // force scroll, since anchor for some reason not working
    element.scrollIntoView();
  }, 50);
};

const anchorToProduct = () => {
  if (!("onpagehide" in window)) {
    return;
  }

  const match = window.location.href.match(removeScrollToQueryRegex);

  window.addEventListener("pageshow", event => {
    if (isPersisted(event)) {
      return;
    }

    if (match) {
      const [, scrollToElementId] = match;
      scrollToElement(document.getElementById(scrollToElementId));
    }
  });

  window.addEventListener("pagehide", event => {
    if (isPersisted(event)) {
      return;
    }

    window.scrollTo(0, 0);
  });
};

export default anchorToProduct;
