const categories = {
  21867: {
    carouselCatId: 26416,
    title: "facebody_plp_bath_carousel_title",
    subtitle: "facebody_plp_bath_carousel_subtitle"
  },
  5678: {
    carouselCatId: 25399,
    title: "facebody_plp_grooming_carousel_title",
    subtitle: "facebody_plp_grooming_carousel_subtitle"
  },
  2238: {
    carouselCatId: 21007,
    title: "facebody_plp_suncare_carousel_title",
    subtitle: "facebody_plp_suncare_carousel_subtitle"
  },
  13210: {
    carouselCatId: 21007,
    title: "facebody_plp_suncare_carousel_title",
    subtitle: "facebody_plp_suncare_carousel_subtitle"
  }
};

export default categories;
