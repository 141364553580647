import getCategoryDetailsReloadingParameters from "./getCategoryDetailsReloadingParameters";

const getDataFetchingParameters = ({
  store,
  location,
  dispatch,
  isNewPage,
  lastKnownGoodLocation,
  category,
  decisions
}) => {
  const catId = category.details?.id;

  const dataFetchingParameters = {
    store,
    location,
    dispatch,
    isNewPage,
    decisions
  };

  if (catId && !isNewPage) {
    return {
      ...dataFetchingParameters,
      ...getCategoryDetailsReloadingParameters({
        prevLocation: lastKnownGoodLocation,
        nextLocation: location,
        category
      })
    };
  }

  return dataFetchingParameters;
};

export default getDataFetchingParameters;
