import { getPaginationData, getTileToFocus } from "./selectors";

const wasFirstPageLoadedMostRecently = state => {
  const {
    page: { initial: pageNumber }
  } = getPaginationData(state);
  const { pageNumber: pageToFocus } = getTileToFocus(state);

  return pageToFocus == 1 || (!pageToFocus && pageNumber == 1);
};

export default wasFirstPageLoadedMostRecently;
