import { CarouselMode } from "@asosteam/asos-web-component-library-carousel";

export const VIEWPORT_TO_PAGE_SIZE = {
  0: "(max-width: 767px)",
  3: "(min-width: 768px) and (max-width: 1023px)",
  4: "(min-width: 1024px) and (max-width: 1199px)",
  5: "(min-width: 1200px) and (max-width: 1369px)",
  6: "(min-width: 1370px)"
};

export const CAROUSEL_PRODUCT_REQUEST_LIMIT = 30;

export const CAROUSEL_MODE_TO_COLUMN_WIDTH = {
  [CarouselMode.endlessSideScroll]: 134,
  [CarouselMode.pagination]: 174
};

export const MAX_PRODUCT_TILES = 6;

export const MINIMUM_SEARCH_RESULTS_FOR_CAROUSEL = 24;

export const CAROUSEL_ID = "upsell-carousel";
