import searchRedirectUrl from "./searchRedirectUrl";
import onlyOneProduct from "./onlyOneProduct";
import categoryPageNumberExceedsAvailableProducts from "./categoryPageNumberExceedsAvailableProducts";

const clientSideRedirect = ({ response, state }) => {
  const rules = [
    searchRedirectUrl,
    onlyOneProduct,
    categoryPageNumberExceedsAvailableProducts
  ];

  return rules.some(rule => rule({ response, state }));
};

export default clientSideRedirect;
