import { clearError } from "../../error";
import { undoPriceRange } from "../range/actions";
import { clearNewSearch, setNewSearch } from "../actions";

const undoSearch = () => dispatch => {
  dispatch(clearNewSearch());
  dispatch(clearError());
  dispatch(undoPriceRange());
  dispatch(setNewSearch());
};

export default undoSearch;
