export const validSortOptions = [
  undefined,
  "freshness",
  "pricedesc",
  "priceasc"
];

const searchLabel = {
  [validSortOptions[0]]: "plp_web_sort_relevance",
  [validSortOptions[1]]: "plp_web_sort_whats_new",
  [validSortOptions[2]]: "plp_web_sort_price_high_to_low",
  [validSortOptions[3]]: "plp_web_sort_price_low_to_high"
};

const categoryLabel = {
  [validSortOptions[0]]: "plp_sort_option_recommended",
  [validSortOptions[1]]: "plp_web_sort_whats_new",
  [validSortOptions[2]]: "plp_web_sort_price_high_to_low",
  [validSortOptions[3]]: "plp_web_sort_price_low_to_high"
};

const labelToOptions = labels =>
  validSortOptions.map(value => ({
    value,
    label: labels[value]
  }));

export const searchOptions = labelToOptions(searchLabel);
export const categoryOptions = labelToOptions(categoryLabel);
