import { useState, useEffect } from "react";
import mediaQueryListenerFactory from "./mediaQueryListListenerFactory";

const useMatchMedia = query => {
  const [matches, setMatches] = useState(null);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    if (mediaQueryList.matches !== matches) {
      setMatches(mediaQueryList.matches);
    }

    const { add, remove } = mediaQueryListenerFactory({
      mediaQueryList,
      listener: () => setMatches(mediaQueryList.matches)
    });

    add();

    return remove;
  }, [matches, query]);

  return matches;
};

export default useMatchMedia;
