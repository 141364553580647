import { createAction } from "redux-actions";
import {
  ANALYTICS_SAVE_FOR_LATER,
  ANALYTICS_PRODUCT_CLICK,
  ANALYTICS_PAGE_LOAD
} from "../constants";

export { default as applyAndRemovePendingEvents } from "./applyAndRemovePendingEvents";
export {
  analyticsAddPendingSmallViewSubmit,
  analyticsAddPendingSortRefinement,
  analyticsPendingClearRefinement,
  analyticsAddPendingSelectAll,
  analyticsAddPendingRefinementsToggled,
  analyticsPendingClearAllRefinements,
  analyticsAddPendingRefinementSelect,
  analyticsRemovePendingEvent
} from "./analyticsPending";

export {
  analyticsOpenFilterSmall,
  analyticsOpenRefinementType,
  analyticsCloseRefinementType,
  analyticsRefinementsCloseMobile,
  analyticsRefinementsSelect,
  analyticsRefinementsDeselect,
  analyticsSelectAll,
  analyticsRefinementsDone,
  analyticsRefinementsSort,
  analyticsRefinementsViewItems,
  analyticsClearRefinement,
  analyticsClearAllRefinements,
  analyticsRefinementsToggled,
  analyticsSmallViewSubmit
} from "./analyticsRefinements";

export {
  analyticsLoadMore,
  analyticsLoadPrevious
} from "./analyticsPagination";

export const analyticsSaveForLater = createAction(ANALYTICS_SAVE_FOR_LATER);
export const analyticsProductClick = createAction(ANALYTICS_PRODUCT_CLICK);
export const analyticsPageLoad = createAction(ANALYTICS_PAGE_LOAD);
