import { handleActions } from "redux-actions";
import {
  ADD_SAVED_PRODUCT,
  UPDATE_SAVED_PRODUCT,
  REMOVE_SAVED_PRODUCT,
  RECEIVE_SAVED_ITEMS_SUMMARY,
  REMOVE_FROM_SUMMARY
} from "./constants";

const initialState = {
  savedProducts: [],
  summary: []
};

export default handleActions(
  {
    [ADD_SAVED_PRODUCT]: (state, { payload }) => ({
      ...state,
      savedProducts: [...state.savedProducts, payload]
    }),
    [UPDATE_SAVED_PRODUCT]: (state, { payload }) => {
      const productIdIndex = state.savedProducts.findIndex(
        item => item.productId === payload.productId
      );

      if (productIdIndex >= 0) {
        const updatedProducts = [...state.savedProducts];
        updatedProducts[productIdIndex] = payload;

        return { ...state, savedProducts: updatedProducts };
      }

      return state;
    },
    [REMOVE_SAVED_PRODUCT]: (state, { payload: productId }) => {
      const productIdIndex = state.savedProducts.findIndex(
        item => item.productId === productId
      );

      if (productIdIndex === -1) {
        return { ...state };
      }

      return {
        ...state,
        savedProducts: [
          ...state.savedProducts.slice(0, productIdIndex),
          ...state.savedProducts.slice(productIdIndex + 1)
        ]
      };
    },
    [RECEIVE_SAVED_ITEMS_SUMMARY]: (state, { payload: summary }) => ({
      ...state,
      savedProducts: [...state.savedProducts, ...summary]
    }),
    [REMOVE_FROM_SUMMARY]: (state, { payload: productId }) => {
      const summaryIndex = state.summary.findIndex(
        item => item.productId === productId
      );

      if (summaryIndex === -1) {
        return { ...state };
      }

      return {
        ...state,
        summary: [
          ...state.summary.slice(0, summaryIndex),
          ...state.summary.slice(summaryIndex + 1)
        ]
      };
    }
  },
  initialState
);
