import dictionaryStringToObject from "../../../helpers/dictionaryStringToObject";

const removeFloorParams = params => {
  const { refine, ...paramsWithoutRefine } = params;

  if (!refine) {
    return { ...paramsWithoutRefine };
  }
  const refineObject = dictionaryStringToObject({
    dictionaryString: refine,
    entriesSeparator: "|",
    keyValueSeparator: ":"
  });

  delete refineObject.floor;

  const refineString = Object.entries(refineObject)
    .map(([key, values]) => `${key}:${values}`)
    .join("|");

  if (refineString) {
    return {
      ...paramsWithoutRefine,
      refine: refineString
    };
  }

  return paramsWithoutRefine;
};

export default removeFloorParams;
