import {
  hasSelectedFacets,
  getProducts
} from "../../../modules/search/selectors";
import { getIsSearchPage } from "../../../modules/page/selectors";

const shouldRedirect = state => {
  const products = getProducts(state);

  return ![
    products.length !== 1,
    hasSelectedFacets(state),
    !getIsSearchPage(state)
  ].some(result => result);
};

export default shouldRedirect;
