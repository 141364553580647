export const defaultStep = 5;

export const getStateForPriceSlider = state => {
  const { min, max, step, selected } = state.search.range.currentprice;
  const hasSelectedValues = isCurrentPriceRangeSelected(state);

  return {
    minValue: min,
    maxValue: max,
    step,
    hasSelectedValues,
    value: {
      min: selected.temp
        ? selected.temp.leftIndicator
        : selected.current.leftIndicator,
      max: selected.temp
        ? selected.temp.rightIndicator
        : selected.current.rightIndicator
    }
  };
};

export const getPriceRangeState = state => state.search.range.currentprice;

export const getNextPriceRange = state =>
  state.search.range.currentprice.selected.temp
    ? state.search.range.currentprice.selected.temp
    : state.search.range.currentprice.selected.current;

export const getPrevPriceRange = state =>
  state.search.range.currentprice.selected.previous;

export const isCurrentPriceRangeSelected = state =>
  getPriceRangeState(state).hasSelectedValues;

export const getPriceRange = state => state.facets.filter(isFacetPriceRange);

export const isFacetPriceRange = facet => facet.id === "currentprice";

export const getPrices = facetValue => Number(facetValue.id);

export const getLowerStep = (prices, step = defaultStep) =>
  Math.floor(Math.min(...prices) / step) * step;

export const getHigherStep = (prices, step = defaultStep) =>
  Math.ceil(Math.max(...prices) / step) * step;

export const isPriceRangeSelected = state =>
  // TODO: instead of every could this be reverse array then find first with true?
  // TODO: verify this works as expected - not sure it should be every. e.g what if I move from intital (hasSelectedValues: false) inward (hasSelectedValues: true) back to initial (hasSelectedValues: false) then back inward (hasSelectedValues: true)?
  getPriceRange(state).every(facet => facet.hasSelectedValues);

export const getMinMaxFromApiResponse = state =>
  getPriceRange(state)
    .map(({ facetValues }) => {
      const prices = facetValues.map(getPrices);

      return {
        min: getLowerStep(prices),
        max: getHigherStep(prices)
      };
    })
    .concat({
      min: null,
      max: null
    })
    .reduce(first => first);

export const calculateHasSelectedValuesState = (
  indicatorValues,
  currentpriceState
) =>
  indicatorValues && currentpriceState
    ? indicatorValues.leftIndicator !== currentpriceState.min ||
      indicatorValues.rightIndicator !== currentpriceState.max
    : false;
