import { handleActions } from "redux-actions";
import {
  SET_PAGE_NAME,
  UNKNOWN_PAGE,
  SET_PAGE_LOADING,
  SET_IS_NEW_PAGE,
  SET_IS_SERVER_RENDERED
} from "./constants";

export const initialState = {
  name: UNKNOWN_PAGE,
  isNewPage: false,
  isServerRendered: true
};

export default handleActions(
  {
    [SET_PAGE_NAME]: (state, action) => ({
      ...state,
      name: action.payload
    }),
    [SET_PAGE_LOADING]: (state, action) => ({
      ...state,
      isLoading: action.payload
    }),
    [SET_IS_NEW_PAGE]: (state, action) => ({
      ...state,
      isNewPage: action.payload
    }),
    [SET_IS_SERVER_RENDERED]: (state, action) => ({
      ...state,
      isServerRendered: action.payload
    })
  },
  initialState
);
