// Actions
export const SET_PAGE_NAME = "SET_PAGE_NAME";
export const SET_PAGE_LOADING = "SET_PAGE_LOADING";
export const SET_IS_NEW_PAGE = "SET_IS_NEW_PAGE";
export const SET_IS_SERVER_RENDERED = "SET_IS_SERVER_RENDERED";

// Page names
export const SEARCH_PAGE = "SEARCH_PAGE";
export const CATEGORY_PAGE = "CATEGORY_PAGE";
export const BRAND_ERROR = "BRAND_ERROR";
export const BRANDS_PAGE = "BRANDS_PAGE";
export const PAGE_NOT_FOUND = "PAGE_NOT_FOUND";
export const BRAND_UNAVAILABLE = "BRAND_UNAVAILABLE";
export const UNKNOWN_PAGE = "UNKNOWN_PAGE";

//EMF-787 Search Results Pre-Filtered by Gender
export const UNISEX_FLOOR = "2001";

export const PAGES = [
  SEARCH_PAGE,
  CATEGORY_PAGE,
  BRANDS_PAGE,
  BRAND_ERROR,
  PAGE_NOT_FOUND,
  BRAND_UNAVAILABLE,
  UNKNOWN_PAGE
];
