import { categoryHasStockButPageDoesNot } from "../../helpers/categoryHasStockButPageDoesNot";

const categoryPageNumberExceedsAvailableProducts = ({ response }) => {
  const { products, itemCount } = response;

  if (
    categoryHasStockButPageDoesNot({
      itemCount,
      productsLength: products?.length
    })
  ) {
    const url = new URL(window.location.href);
    url.searchParams.delete("page");

    window.location.replace(`${url.pathname}${url.search}`);

    return true;
  }

  return false;
};

export default categoryPageNumberExceedsAvailableProducts;
