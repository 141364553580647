import { getCategoryId } from "../../category/selectors";
import { getSearchTerm } from "../../search/selectors";
import { getPageName } from "../../page/selectors";
import { CATEGORY_PAGE, SEARCH_PAGE } from "../../page/constants";

const getPageLevelTargeting = state => {
  const pageType = getPageName(state);
  const targeting = {
    [CATEGORY_PAGE]: () => ({ cid: getCategoryId(state).toString() }),
    [SEARCH_PAGE]: () => ({ q: getSearchTerm(state) })
  }[pageType]();

  return targeting;
};

export default getPageLevelTargeting;
