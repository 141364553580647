import shouldRefreshBeacons from "./shouldRefreshBeacons";

const getRequestParameters = ({
  pageNumber,
  organicProductsCount,
  categoryId,
  searchTerm,
  filters,
  sponsoredProductIds,
  isSearchPage,
  isSorted
}) => {
  const parameters = {
    "page-number": pageNumber,
    "list-size": organicProductsCount,
    filters
  };

  if (isSearchPage) {
    parameters.keywords = searchTerm;
  } else {
    parameters.category = categoryId;
  }

  if (shouldRefreshBeacons({ sponsoredProductIds, isSorted })) {
    parameters["item-whitelist"] = sponsoredProductIds;
  }

  return parameters;
};

export default getRequestParameters;
