export const ANALYTICS_LOAD_MORE = "ANALYTICS_LOAD_MORE";
export const ANALYTICS_LOAD_PREVIOUS = "ANALYTICS_LOAD_PREVIOUS";
export const ANALYTICS_OPEN_REFINEMENTS_SMALL =
  "ANALYTICS_OPEN_REFINEMENTS_SMALL";
export const ANALYTICS_CLEAR_REFINEMENT = "ANALYTICS_CLEAR_REFINEMENT";
export const ANALYTICS_SELECT_ALL = "ANALYTICS_SELECT_ALL";
export const ANALYTICS_CLEAR_ALL_REFINEMENTS =
  "ANALYTICS_CLEAR_ALL_REFINEMENTS";
export const ANALYTICS_OPEN_REFINEMENT_TYPE = "ANALYTICS_OPEN_REFINEMENT_TYPE";
export const ANALYTICS_CLOSE_REFINEMENT_TYPE =
  "ANALYTICS_CLOSE_REFINEMENT_TYPE";
export const ANALYTICS_REFINEMENTS_DONE = "ANALYTICS_REFINEMENTS_DONE";
export const ANALYTICS_REFINEMENTS_SELECT = "ANALYTICS_REFINEMENTS_SELECT";
export const ANALYTICS_REFINEMENTS_DESELECT = "ANALYTICS_REFINEMENTS_DESELECT";
export const ANALYTICS_REFINEMENTS_TOGGLED = "ANALYTICS_REFINEMENTS_TOGGLED";
export const ANALYTICS_SAVE_FOR_LATER = "ANALYTICS_SAVE_FOR_LATER";
export const ANALYTICS_PRODUCT_CLICK = "ANALYTICS_PRODUCT_CLICK";
export const ANALYTICS_PAGE_LOAD = "ANALYTICS_PAGE_LOAD";
export const ANALYTICS_REFINEMENTS_SORT = "ANALYTICS_REFINEMENTS_SORT";
export const ANALYTICS_REFINEMENTS_VIEW_ITEMS =
  "ANALYTICS_REFINEMENTS_VIEW_ITEMS";
export const ANALYTICS_REFINEMENTS_CLOSE_MOBILE =
  "ANALYTICS_REFINEMENTS_CLOSE_MOBILE";
export const ANALYTICS_SMALL_VIEW_SUBMIT = "ANALYTICS_SMALL_VIEW_SUBMIT";
export const ANALYTICS_ADD_PENDING_EVENT = "ANALYTICS_ADD_PENDING_EVENT";
export const ANALYTICS_REMOVE_PENDING_EVENT = "ANALYTICS_REMOVE_PENDING_EVENT";
