import { mapPrice, mapReducedPrice } from "../mapPrice";
import { mapBadgeType } from "./mapBadge";
import { getHasMoreColours } from "./getHasMoreColours";
import { getIsSponsored } from "./getIsSponsored";

export const mapProduct = ({ product }) => {
  const price = mapPrice(product.price);
  const reducedPrice = mapReducedPrice(product.price);
  const hasMoreColours = getHasMoreColours({
    facetGroupings: product.facetGroupings
  });

  let advertisement = {
    id: product.advertisement?.id,
    variantId: product.advertisement?.variantId
  };

  if (CLIENT && product.advertisement) {
    advertisement = product.advertisement;
  }

  return {
    id: product.id,
    productCode: product.productCode,
    url: product.url,
    price,
    description: product.name,
    image: product.imageUrl,
    badgeType: mapBadgeType({ ...product, hasMoreColours }),
    productType: product.productType,
    colourWayId: product.colourWayId,
    isSale: product.price.isMarkedDown,
    reducedPrice,
    hasMultiplePrices: product.hasMultiplePrices,
    isOutlet: product.price.isOutletPrice,
    isSellingFast: product.isSellingFast,
    brandName: product.brandName,
    colour: product.colour,
    hasMoreColours,
    priceLocalCurrency: reducedPrice || price,
    isSponsored: getIsSponsored(product.advertisement),
    isRestockingSoon: product.isRestockingSoon,
    advertId: product.advertisement?.id,
    advertisement,
    additionalImageUrls: product.additionalImageUrls,
    videoUrl: product.videoUrl,
    showVideo: product.showVideo,
    isPromotion: product.isPromotion
  };
};

export default mapProduct;
