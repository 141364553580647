const CHANGE_EVENT_NAME = "change";

export default ({ mediaQueryList, listener }) => {
  const [add, remove] =
    window.matchMedia("") instanceof EventTarget
      ? [
          () => mediaQueryList.addEventListener(CHANGE_EVENT_NAME, listener),
          () => mediaQueryList.removeEventListener(CHANGE_EVENT_NAME, listener)
        ]
      : [
          () => mediaQueryList.addListener(listener),
          () => mediaQueryList.removeListener(listener)
        ];

  return { add, remove };
};
