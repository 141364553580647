import { getCanonicalUrlFromCategoryDetailsByLocale } from "../category/selectors";
import { queryStringToObject } from "../../../helpers/queryStringToObject";

const getLocationFromState = state => {
  if (!state || !state.router) {
    return;
  }
  if (state.router.locationBeforeTransitions) {
    return state.router.locationBeforeTransitions;
  }
  const location = state.router?.location;

  return location?.search
    ? { ...location, query: queryStringToObject(location.search) }
    : location;
};

const getLocationFromBrowserHistory = () => {
  if (CLIENT) {
    const { history } = require("../../../client/history");

    const location = history.location;
    const locationWithQuery = {
      ...location,
      query: queryStringToObject(location.search)
    };

    return locationWithQuery;
  }
};

export const getLocation = state => {
  const locationFromState = getLocationFromState(state);
  if (locationFromState) {
    return locationFromState;
  }

  return getLocationFromBrowserHistory();
};

export const getOriginalCurrentPriceRange = state => {
  const { currentpricerange = "" } = getLocation(state).query;

  if (currentpricerange === "") return {};

  return currentpricerange
    .split("-")
    .map(Number)
    .reduce((min, max) => ({ min, max }));
};

export const getCanonicalPath = state => {
  const canonicalUrlFromApi = getCanonicalUrlFromCategoryDetailsByLocale(state);
  if (canonicalUrlFromApi !== "") {
    return new URL(canonicalUrlFromApi).pathname;
  }

  return getLocation(state).pathname;
};
