import config from "../../config/common";
import handleErrors from "./handleErrors";
import { getConfiguredRequest } from "../request";

const {
  api: {
    product: {
      search: { retries }
    }
  }
} = config;

const webRequest = getConfiguredRequest({
  validateStatus: null,
  retries
});

export const request = (url, options, requestType, uuid) =>
  webRequest
    .get({ url, uuid, ...options, additionalLoggingParams: { requestType } })
    .then(handleErrors(requestType))
    .then(({ data }) => data);
