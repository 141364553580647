import PropTypes from "prop-types";
import { Provider as StateProvider } from "react-redux";
import LocaleProvider from "../../components/LocaleProvider";
import { Switch } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import ErrorMessage from "../../components/ErrorMessage";
import routes from "../../routes";
import { FeaturesProvider } from "../Features";

const App = ({ store, translations, RouterComponent, experiments }) => (
  <FeaturesProvider experiments={experiments}>
    <StateProvider store={store}>
      <LocaleProvider translations={translations}>
        <ErrorBoundary ErrorComponent={ErrorMessage}>
          <RouterComponent>
            <Switch>{routes}</Switch>
          </RouterComponent>
        </ErrorBoundary>
      </LocaleProvider>
    </StateProvider>
  </FeaturesProvider>
);

App.propTypes = {
  store: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  RouterComponent: PropTypes.any.isRequired,
  experiments: PropTypes.object
};

export default App;
