import getTranslations from "../../../i18n";
import { hydrate } from "react-dom";
import App from "../../containers/App";
import { getLocale } from "../../state/modules/config/selectors";
import { loadableReady } from "@loadable/component";
import getSavedItemsSummary from "../saveForLater/getSavedItemsSummary";
import loadPolyfills from "./loadPolyfills";
import removeScrollToQuery from "../../helpers/removeScrollToQuery";
import { history } from "../history";
import ClientRouter from "../../containers/ClientRouter";
import initialiseAdvertsSdk from "../adverts/initialiseAdvertsSdk";

const bootstrapReact = async store => {
  await loadPolyfills();

  initialiseAdvertsSdk(store);

  store.dispatch(getSavedItemsSummary());

  removeScrollToQuery(history);

  const [translations] = await Promise.all([
    getTranslations(getLocale(store.getState()))
  ]);

  loadableReady(() => {
    hydrate(
      <App
        store={store}
        translations={translations}
        RouterComponent={ClientRouter}
      />,
      document.getElementById("plp")
    );
  });
};

export default bootstrapReact;
