import commonConfig from "../config/common";

const {
  globalKeys: { asosKey, sdkKey, eventBusKey }
} = commonConfig;

const getSdk = sdkName => window[asosKey][sdkKey][sdkName];

const sdkExists = sdkName => !!getSdk(sdkName);

const sdkProvider = sdkName =>
  new Promise(resolve => {
    const resolveSdk = () => {
      const sdk = getSdk(sdkName);
      resolve(sdk);
    };

    if (sdkExists(sdkName)) {
      return resolveSdk();
    }

    window[asosKey][eventBusKey].once(`${sdkName}-sdk-ready`, resolveSdk);
  });

export default sdkProvider;
