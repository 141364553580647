import loadable from "@loadable/component";
import BrandsPageSkeleton from "../../components/BrandsPage/BrandsPageSkeleton";

const BrandsPageRoute = loadable(
  () =>
    import(
      /* webpackPreload: true, webpackChunkName: "BRANDS_PAGE" */ "../BrandsPage"
    ),
  {
    fallback: <BrandsPageSkeleton />
  }
);

export default BrandsPageRoute;
