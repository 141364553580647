import config from "../../../../../../../config/common";

export const getProductsWithinVicinity = products => {
  const {
    adverts: { targetingVicinity, adPosition }
  } = config;
  const position = Math.min(adPosition, products.length);

  return [
    ...products.slice(
      Math.max(0, position - targetingVicinity),
      position + targetingVicinity
    )
  ];
};
