import styles from "./Results.css";
import ResultsProgressOverlay from "./ResultsProgressOverlay";
import { useScrollToTopOnMount } from "../hooks";

const ProductListSkeleton = () => {
  const tiles = [...Array(8).keys()];

  useScrollToTopOnMount();

  return (
    <div className={styles.skeletonContainer}>
      <ResultsProgressOverlay />
      <div className={styles.skeletonHeader} id="skeletonHeader" />
      <div className={styles.skeletonFilters} id="skeletonFilters" />
      <ul className={styles.skeletonTiles}>
        {tiles.map((tile, index) => (
          <li
            key={index}
            className={styles.skeletonProductTile}
            id="skeletonProductTile"
          >
            <div className={styles.skeletonProductImage} />
            <div className={styles.skeletonTextOne} />
            <div className={styles.skeletonTextTwo} />
            <div className={styles.skeletonTextThree} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductListSkeleton;
