import { handleActions } from "redux-actions";
import {
  ANALYTICS_ADD_PENDING_EVENT,
  ANALYTICS_REMOVE_PENDING_EVENT
} from "./constants";

export default handleActions(
  {
    [ANALYTICS_ADD_PENDING_EVENT]: (state, { payload }) => ({
      ...state,
      pendingAnalytics: {
        ...payload
      }
    }),
    [ANALYTICS_REMOVE_PENDING_EVENT]: state => ({
      ...state,
      pendingAnalytics: {}
    })
  },
  { pendingAnalytics: {} }
);
