import { UPDATE_IMAGE_GALLERY_DISMISS } from "./constants";

const reducer = {
  [UPDATE_IMAGE_GALLERY_DISMISS]: (state, action) => ({
    ...state,
    imageGalleryOnboardDismissed: action.payload.imageGalleryOnboardDismissed
  })
};

export default reducer;
