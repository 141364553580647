import getQueryString from "./getQueryString";
import { request } from "../";
import config from "../../../config/common";
import {
  SEARCH_REQUEST,
  SEARCH_CATEGORY_REQUEST,
  SEARCH_CURATIONS_REQUEST
} from "../constants";
import getSearchBaseUrl from "./getSearchBaseUrl";
import removeFloorParams from "./removeFloorParams";
import { CURATED_CATEGORY_VERSION_QUERY } from "./constants";

const {
  api: {
    product: {
      search: { timeout, search: searchUrl, categories, curations }
    },
    curatedCategory: { version: curationVersion }
  }
} = config;

export const search = (
  params = {},
  uuid,
  xSiteOriginFromServerRequest,
  withNoFloor
) => {
  const { q } = params;
  if (!q) {
    const error = new Error("The query string must contain a q parameter");

    return Promise.reject(error);
  }

  if (withNoFloor) {
    params = removeFloorParams(params);
  }

  const qs = getQueryString(params);
  const url = `${getSearchBaseUrl(
    xSiteOriginFromServerRequest
  )}${searchUrl}?${qs}`;

  return request(url, { timeout }, SEARCH_REQUEST, uuid);
};

export const searchCategory = (
  params = {},
  uuid,
  xSiteOriginFromServerRequest
) => {
  const { cid } = params;
  if (!cid) {
    const error = new Error("The query string must contain a cid parameter");

    return Promise.reject(error);
  }

  const qs = getQueryString(params);
  const url = `${getSearchBaseUrl(
    xSiteOriginFromServerRequest
  )}${categories}/${cid}?${qs}`;

  return request(url, { timeout }, SEARCH_CATEGORY_REQUEST, uuid);
};

export const searchCuratedCategory = (
  params = {},
  uuid,
  xSiteOriginFromServerRequest
) => {
  const { cid } = params;
  if (!cid) {
    const error = new Error("The query string must contain a cid parameter");

    return Promise.reject(error);
  }

  const qs = getQueryString({
    ...params,
    [CURATED_CATEGORY_VERSION_QUERY]: curationVersion
  });
  const url = `${getSearchBaseUrl(
    xSiteOriginFromServerRequest
  )}${curations}/${cid}?${qs}`;

  return request(url, { timeout }, SEARCH_CURATIONS_REQUEST, uuid);
};
