import {
  getCountryCode,
  getCurrencyCode,
  getSizeSchema,
  getLocale
} from "../../config/selectors";

const getAppLevelTargeting = state => ({
  browseCountry: getCountryCode(state),
  browseCurrency: getCurrencyCode(state),
  browseSizeSchema: getSizeSchema(state),
  browseLanguage: getLocale(state)
});

export default getAppLevelTargeting;
