import {
  isFacetPriceRange,
  isCurrentPriceRangeSelected
} from "./priceRangeSelectors";
import config from "../../../config/common";
import { buildProduct } from "./productTile/selectors";
export { getRequestId } from "./getRequestId";
export { getItemCount } from "./getItemCountSelector";
export { default as getAllAppliedRefinements } from "./facets/getAllAppliedRefinements";

export const getPageSize = state =>
  state.search?.pageSize ?? config.api.product.search.pageSize;

export const getOffset = state => state.search.offset;

export const getProducts = ({ search: { products } }) => products;
export const getSearchTerm = ({ search: { searchTerm } }) => searchTerm;
export const getIsNewSearch = ({ search: { isNewSearch } }) => isNewSearch;
export const getSearchMetadata = ({ search: { searchPassMeta } }) =>
  searchPassMeta || {};
export const getIsLoading = ({ search: { isLoading } }) => isLoading;
export const getShowLoadMore = ({ search: { showLoadMore } }) => showLoadMore;
export const getShowLoadPrevious = ({ search: { showLoadPrevious } }) =>
  showLoadPrevious;
export const getTileToFocus = ({ search: { tileToFocus } }) => tileToFocus;
export const getIsNewPage = ({ search: { isNewPage } }) => isNewPage;
export const getIsLoadingPrevious = ({ search: { isLoadingPrevious } }) =>
  isLoadingPrevious;
export const getPersonalisationParams = ({
  search: { personalisationParams }
}) => personalisationParams;
export const getAdditionalQueryParams = ({
  search: { additionalQueryParams }
}) => additionalQueryParams;

export const getPages = state => {
  const offset = getOffset(state).lower / getPageSize(state);

  const getPageIndex = index => Math.floor(index / getPageSize(state));

  const getIndexOnThePage = index => index % getPageSize(state);

  return state.search.products.reduce((pages, product, productIndex) => {
    const pageIndex = getPageIndex(productIndex);
    const pageNumber = offset + pageIndex + 1;

    const onPageProductIndex = getIndexOnThePage(productIndex);

    const pageItem = {
      product: buildProduct(
        {
          product,
          productIndex
        },
        state
      ),
      onPageProductIndex
    };

    pages[pageIndex] = pages[pageIndex]
      ? {
          ...pages[pageIndex],
          products: [...pages[pageIndex].products, pageItem]
        }
      : {
          products: [pageItem],
          pageNumber
        };

    return pages;
  }, []);
};

export const getSelectedFacetValuesByFacet = (state, facet) => {
  const selectedFacetValues = [];
  state.search.selectedFacets
    .filter(selectedFacet => selectedFacet.facetId === facet.id)
    .forEach(selectedValue => {
      const currentFacetValueIndex = selectedFacetValues.findIndex(
        facetValue => facetValue.id === selectedValue.valueId
      );

      const currentFacetValue = facet.facetValues.find(
        facetValue => facetValue.id === selectedValue.valueId
      );
      if (currentFacetValueIndex > -1) {
        selectedFacetValues[currentFacetValueIndex] = {
          ...currentFacetValue,
          isSelected: selectedValue.isSelected
        };
      } else {
        selectedFacetValues.push({
          ...currentFacetValue,
          isSelected: selectedValue.isSelected
        });
      }
    });

  return selectedFacetValues;
};

export const hasSelectedFacets = state =>
  state.search.facets.some(({ hasSelectedValues }) => hasSelectedValues);

export const hasFacets = state => state.search.facets.length > 0;

const filterZeroCountOrNotSelected = facet => {
  facet.facetValues = facet.facetValues.filter(
    facetValue => facetValue.count > 0 || facetValue.isSelected
  );
};

export const getFacets = state => {
  if (!state.search.selectedFacets.length)
    return state.search.facets.map(f => {
      const updatedFacet = JSON.parse(JSON.stringify(f));

      filterZeroCountOrNotSelected(updatedFacet);

      return updatedFacet;
    });

  return state.search.facets.map(f => {
    const updatedFacet = JSON.parse(JSON.stringify(f));
    const matchingSelections = state.search.selectedFacets.filter(
      s => s.facetId === updatedFacet.id
    );
    matchingSelections.forEach(s => {
      const fvIndex = updatedFacet.facetValues.findIndex(
        fv => fv.id === s.valueId
      );

      updatedFacet.facetValues[fvIndex] = {
        ...updatedFacet.facetValues[fvIndex],
        isSelected: s.isSelected
      };
    });

    filterZeroCountOrNotSelected(updatedFacet);

    if (isFacetPriceRange(updatedFacet)) {
      updatedFacet.hasSelectedValues = isCurrentPriceRangeSelected(state);
    } else {
      updatedFacet.hasSelectedValues = updatedFacet.facetValues.some(
        facetValue => facetValue.isSelected
      );
    }

    return updatedFacet;
  });
};

export const getSort = state =>
  state.search.selectedSort &&
  Object.keys(state.search.selectedSort).includes("value")
    ? state.search.selectedSort.value
    : state.search.query && state.search.query.sort;

const getPaginationPage = ({
  showLoadMore,
  showLoadPrevious,
  initialPageNumber,
  itemCount,
  offset,
  pageSize
}) => ({
  previous: showLoadPrevious ? Math.ceil(offset.lower / pageSize) : null,
  initial: initialPageNumber,
  next: showLoadMore ? Math.ceil(offset.upper / pageSize) + 1 : null,
  max: Math.ceil(itemCount / pageSize)
});

const getPaginationOffset = ({
  showLoadMore,
  showLoadPrevious,
  offset,
  pageSize
}) => ({
  previous: showLoadPrevious ? offset.lower - pageSize : null,
  initial: offset.initial,
  next: showLoadMore ? offset.upper : null
});

export const getPaginationData = (
  state,
  pageSize = config.api.product.search.pageSize
) => {
  const { itemCount, showLoadMore, showLoadPrevious, offset } = state.search;

  const hasResults = itemCount > 0;

  if (!hasResults) {
    return {
      meta: {
        hasResults,
        canLoadMore: showLoadMore,
        canLoadPrevious: showLoadPrevious
      },
      page: {
        previous: null,
        initial: null,
        next: null,
        max: null
      },
      offset: {
        previous: null,
        initial: null,
        next: null
      }
    };
  }

  // Plus one because our pages are not zero indexed i.e. the first page is page one
  const initialPageNumber = Math.ceil(offset.initial / pageSize) + 1;

  return {
    meta: {
      hasResults,
      canLoadMore: showLoadMore,
      canLoadPrevious: showLoadPrevious
    },
    page: getPaginationPage({
      showLoadMore,
      showLoadPrevious,
      initialPageNumber,
      itemCount,
      offset,
      pageSize
    }),
    offset: getPaginationOffset({
      showLoadMore,
      showLoadPrevious,
      offset,
      pageSize
    })
  };
};

export const getPaginationInitial = state =>
  getPaginationData(state).page.initial;

export const getPaginationMax = state => getPaginationData(state).page.max;

export const getRecommendationsAnalytics = state =>
  state.search.diagnostics.recommendationsAnalytics;

export const getAdvertisementsAnalytics = state =>
  state.search.diagnostics.advertisementsAnalytics;

// Maybe use it directly in getFacets?
export const getFacetSelectedValues = facet => {
  const { hasSelectedValues, facetValues } = facet;

  return hasSelectedValues
    ? facetValues
        .filter(({ isSelected }) => isSelected)
        .map(({ name }) => name)
        .join(", ")
    : "";
};

export const getFacetTotalSelected = facet => {
  const { hasSelectedValues, facetValues } = facet;

  return hasSelectedValues
    ? facetValues.filter(({ isSelected }) => isSelected).length
    : 0;
};

export const isUrlWithQueryString = url => url.indexOf("?") !== -1;

export const isExactMatch = state => {
  const { isPartial, isSpellcheck } = getSearchMetadata(state);

  return isPartial === false && isSpellcheck === false;
};

export const getSearchPassesName = state => {
  const searchPass = getSearchMetadata(state).searchPass || [];

  return searchPass.map(({ name }) => name.toLowerCase());
};

export const getSuggestTerms = state =>
  getSearchMetadata(state).alternateSearchTerms;

export { getHasShowableVideo } from "./getHasShowableVideo";
