import { createAction } from "redux-actions";
import { getProducts, getItemCount } from "../../../search/selectors";
import {
  ANALYTICS_OPEN_REFINEMENTS_SMALL,
  ANALYTICS_OPEN_REFINEMENT_TYPE,
  ANALYTICS_CLOSE_REFINEMENT_TYPE,
  ANALYTICS_REFINEMENTS_CLOSE_MOBILE,
  ANALYTICS_REFINEMENTS_SELECT,
  ANALYTICS_REFINEMENTS_DESELECT,
  ANALYTICS_SELECT_ALL,
  ANALYTICS_REFINEMENTS_DONE,
  ANALYTICS_REFINEMENTS_SORT,
  ANALYTICS_REFINEMENTS_VIEW_ITEMS,
  ANALYTICS_CLEAR_REFINEMENT,
  ANALYTICS_CLEAR_ALL_REFINEMENTS
} from "../../constants";

const payloadCreator = (payload = {}, { products = [], totalStyles }) => ({
  ...payload,
  totalStyles,
  returnedProductList: products
});

const unaryCreateAction = type => createAction(type, payloadCreator);

const enrichAction = actionCreator => payload => (dispatch, getState) => {
  const state = getState();
  const products = getProducts(state);
  const totalStyles = getItemCount(state);
  dispatch(actionCreator(payload, { products, totalStyles }));
};

const refinements = [
  ANALYTICS_OPEN_REFINEMENTS_SMALL,
  ANALYTICS_OPEN_REFINEMENT_TYPE,
  ANALYTICS_CLOSE_REFINEMENT_TYPE,
  ANALYTICS_REFINEMENTS_CLOSE_MOBILE,
  ANALYTICS_REFINEMENTS_SELECT,
  ANALYTICS_REFINEMENTS_DESELECT,
  ANALYTICS_SELECT_ALL,
  ANALYTICS_REFINEMENTS_DONE,
  ANALYTICS_REFINEMENTS_SORT,
  ANALYTICS_REFINEMENTS_VIEW_ITEMS,
  ANALYTICS_CLEAR_REFINEMENT,
  ANALYTICS_CLEAR_ALL_REFINEMENTS
]
  .map(unaryCreateAction)
  .map(enrichAction);

const [
  analyticsOpenFilterSmall,
  analyticsOpenRefinementType,
  analyticsCloseRefinementType,
  analyticsRefinementsCloseMobile,
  analyticsRefinementsSelect,
  analyticsRefinementsDeselect,
  analyticsSelectAll,
  analyticsRefinementsDone,
  analyticsRefinementsSort,
  analyticsRefinementsViewItems,
  analyticsClearRefinement,
  analyticsClearAllRefinements
] = refinements;

export {
  analyticsOpenFilterSmall,
  analyticsOpenRefinementType,
  analyticsCloseRefinementType,
  analyticsRefinementsCloseMobile,
  analyticsRefinementsSelect,
  analyticsRefinementsDeselect,
  analyticsSelectAll,
  analyticsRefinementsDone,
  analyticsRefinementsSort,
  analyticsRefinementsViewItems,
  analyticsClearRefinement,
  analyticsClearAllRefinements
};
