import { buildUrl, getStoreCode, getStoreUrl } from "../../config/selectors";
import getProductTreatment from "../../../../helpers/getProductTreatment";

export const buildProduct = ({ product, productIndex }, state) => ({
  ...product,
  url: buildUrl(
    {
      storeUrl: getStoreUrl(state),
      storeCode: getStoreCode(state)
    },
    product.url
  ),
  treatment: getProductTreatment({ product, productIndex }, state)
});
