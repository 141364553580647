import { history } from "./history";
import commonConfig from "../config/common";
import configureStore from "../state/store";

const setupStore = () => {
  const {
    globalKeys: { asosKey, plpKey, storeKey }
  } = commonConfig;
  const initialState = window[asosKey][plpKey][storeKey];
  delete window[asosKey][plpKey][storeKey];
  const store = configureStore(history, initialState);

  return store;
};

export default setupStore;
