import customSort from "../../../../helpers/sortByProp";

const REFINEMENT_SEPARATOR = "|";
const PRICE_SEPARATOR = "<";
const ID_VALUES_SEPARATOR = ":";

const getQuery = state => state.search.query || { query: {} };

const getFacet = (state, facetId) =>
  state.search?.facets?.find(({ id }) => id === facetId);

const getSelectedFacetValues = (state, facetId) =>
  getFacet(state, facetId)?.facetValues?.filter(({ isSelected }) => isSelected);

const alphabetically = customSort(refinement => refinement.refinementId);

const getPriceMinMax = refinementValues => {
  const [min, max] = refinementValues.split(PRICE_SEPARATOR);

  return {
    min: Number(min),
    max: Number(max)
  };
};

const getRefinementFromQuery = query => {
  const [refinementId, refinementValues] = query.split(ID_VALUES_SEPARATOR);

  return {
    refinementId,
    refinementValues
  };
};

const buildRefinements = (state, refine) =>
  refine.split(REFINEMENT_SEPARATOR).reduce((refinements, refinementQuery) => {
    const { refinementId, refinementValues } = getRefinementFromQuery(
      refinementQuery
    );

    const createDefaultRefinement = () => ({
      refinementId,
      refinementName: getFacet(state, refinementId)?.name
    });

    const createRefinementWithSelections = () => ({
      ...createDefaultRefinement(),
      selections:
        getSelectedFacetValues(state, refinementId)?.map(({ id, name }) => ({
          selectionId: id,
          selectionName: name
        })) || []
    });

    const createPrice = () => ({
      ...createDefaultRefinement(),
      ...getPriceMinMax(refinementValues)
    });

    const createRefinement = () => {
      switch (refinementId) {
        case "currentprice":
          return createPrice();
        default:
          return createRefinementWithSelections();
      }
    };

    return refinementId ? refinements.concat(createRefinement()) : refinements;
  }, []);

const buildSort = sort => [
  {
    refinementId: "sort",
    refinementName: "sort",
    selections: [{ selectionId: sort }]
  }
];

const getAllAppliedRefinements = state => {
  const { sort, refine = "" } = getQuery(state);
  const refinements = buildRefinements(state, refine).sort(alphabetically);

  return sort ? refinements.concat(buildSort(sort)) : refinements;
};

export { getAllAppliedRefinements as default, getQuery };
