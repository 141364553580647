export const LOG_LEVEL_ERROR = "error";
export const LOG_LEVEL_WARN = "warn";
export const LOG_LEVEL_INFO = "info";
export const LOG_LEVEL_DEBUG = "debug";

export const ALL_LOG_LEVELS = [
  LOG_LEVEL_ERROR,
  LOG_LEVEL_WARN,
  LOG_LEVEL_INFO,
  LOG_LEVEL_DEBUG
];
