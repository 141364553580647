import { handleActions } from "redux-actions";
import {
  defaultStep,
  getPriceRange,
  calculateHasSelectedValuesState
} from "./priceRangeSelectors";
import { hasFacets } from "./selectors";
import { getNewRangeState } from "./priceRangeReducerHelper";
import {
  REQUEST_SEARCH,
  RECEIVE_SEARCH,
  UPDATE_MULTI_FACET,
  UPDATE_RANGE_FACET,
  UPDATE_PERSONALISATION_PARAMS,
  SET_ADDITIONAL_QUERY_PARAMS,
  CLEAR_RANGE_FACET,
  CLEAR_SELECTIONS,
  SET_RANGE_VALUES,
  MORE_PRODUCTS_LOADED,
  PREVIOUS_PRODUCTS_LOADED,
  LOADING_MORE_PRODUCTS,
  LOADING_PREVIOUS_PRODUCTS,
  UNDO_RANGE_VALUES,
  UPDATE_SORT_FACET,
  SET_NEW_SEARCH,
  CLEAR_NEW_SEARCH,
  RESTORE_OLD_SELECTED_FACETS,
  CLEAR_ON_VIEW_BEACON,
  CLEAR_ON_LOAD_BEACON,
  REFRESH_ADVERTISEMENT_BEACONS
} from "./constants";
import { mapAndInsertProducts } from "./product";
import config from "../../../config/common";
import {
  extendOffsetLowerBound,
  extendOffsetUpperBound,
  getOffsetFromQuery
} from "./offset";
import productTileReducer from "./productTile/reducer";
import imageGalleryReducer from "./__variants__/plp-mweb-image-gallery/reducer";
import promoLabelReducer from "./__variants__/plp-promo-label/reducer";
import getPreviousPageNumber from "./getPreviousPageNumber";
import getNextPageNumber from "./getNextPageNumber";

const pageSize = config.api.product.search.pageSize;

export {
  fetchSearch,
  fetchCategory,
  changeMultiFacet,
  changeMultiFacetAndApplyIfLargeMedium,
  changeRangeFacet,
  applyRangeFacet,
  applyFacets,
  clearAll,
  selectAll,
  clear,
  clearSelections,
  retrySearch,
  getSearchAction,
  restoreOldSelectedFacets,
  updatePersonalisationParams,
  setAdditionalQueryParams,
  sendPlacementOnLoadBeacon,
  refreshAdvertisementBeacons,
  done
} from "./actions";

export {
  getPages,
  getPaginationData,
  getPaginationInitial,
  getPaginationMax,
  getSort,
  hasSelectedFacets,
  hasFacets,
  getFacets,
  getItemCount,
  getFacetSelectedValues,
  getFacetTotalSelected,
  getSearchTerm,
  isExactMatch,
  getSuggestTerms
} from "./selectors";
export { getStateForPriceSlider } from "./priceRangeSelectors";
export { default as getPageNumber } from "./getPageNumber";

const defaultPageSize = 72;

export const initialState = {
  searchTerm: "",
  redirectUrl: "",
  products: [],
  facets: [],
  selectedFacets: [],
  selectedSort: {},
  error: null,
  isLoading: false,
  isNewSearch: true,
  itemCount: 0,
  offset: {
    initial: 0,
    lower: 0,
    upper: pageSize
  },
  isLoadingMore: false,
  isLoadingPrevious: false,
  showLoadMore: false,
  tileToFocus: {
    first: false,
    last: false,
    pageNumber: null
  },
  range: {
    currentprice: {
      selected: {
        current: null,
        temp: null
      },
      min: 0,
      max: 0,
      step: defaultStep,
      hasSelectedValues: false
    }
  },
  diagnostics: {
    recommendationsAnalytics: {}
  },
  searchPassMeta: {
    alternateSearchTerms: []
  },
  oldSelectedFacets: [],
  personalisationParams: {},
  pageSize: defaultPageSize
};

const shouldShowLoadMore = (offset, itemCount) => offset.upper < itemCount;

const shouldShowLoadPrevious = offset => offset.lower > 0;

const hasFilterReturnedNoResults = (newItemCount, previousState) =>
  newItemCount === 0 && hasFacets({ search: previousState });

const filterCurrentPrice = selectedFacets =>
  selectedFacets.filter(filter => filter.facetId === "currentprice");

const getReceiveSearchDiagnostic = diagnostics => {
  if (CLIENT) {
    return diagnostics;
  }

  return {
    ...diagnostics,
    advertisementsAnalytics: null
  };
};
export default handleActions(
  {
    ...productTileReducer,
    ...imageGalleryReducer,
    ...promoLabelReducer,
    [LOADING_PREVIOUS_PRODUCTS]: state => ({
      ...state,
      isLoadingPrevious: true,
      isNewSearch: false
    }),
    [PREVIOUS_PRODUCTS_LOADED]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          isLoadingPrevious: false,
          tileToFocus: {
            ...initialState.tileToFocus
          }
        };
      }
      const offset = extendOffsetLowerBound(state, payload);
      const { products: existingProducts } = state;
      const { products } = mapAndInsertProducts({
        existingProducts,
        products: payload.products
      });

      const diagnostics = payload.diagnostics;

      return {
        ...state,
        offset,
        tileToFocus: {
          first: false,
          last: true,
          pageNumber: getPreviousPageNumber(state)
        },
        products,
        diagnostics,
        isLoadingPrevious: false,
        showLoadPrevious: shouldShowLoadPrevious(offset),
        error: null
      };
    },
    [LOADING_MORE_PRODUCTS]: state => ({
      ...state,
      isLoadingMore: true,
      isNewSearch: false
    }),
    [MORE_PRODUCTS_LOADED]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          isLoadingMore: false,
          tileToFocus: {
            ...initialState.tileToFocus
          }
        };
      }
      const { products: existingProducts } = state;
      const offset = extendOffsetUpperBound(state, payload);
      const { products } = mapAndInsertProducts({
        existingProducts,
        products: payload.products,
        insertionIndex: state.products.length
      });
      const diagnostics = payload.diagnostics;

      return {
        ...state,
        offset,
        tileToFocus: {
          first: true,
          last: false,
          pageNumber: getNextPageNumber(state)
        },
        products,
        diagnostics,
        isLoadingMore: false,
        showLoadMore: shouldShowLoadMore(offset, payload.itemCount),
        error: null
      };
    },
    [REQUEST_SEARCH]: (state, { payload }) => ({
      ...state,
      query: payload.query,
      isLoading: true,
      isNewPage: payload.isNewPage,
      isNewSearch: true
    }),
    [RECEIVE_SEARCH]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          isLoading: SERVER,
          tileToFocus: {
            ...initialState.tileToFocus
          }
        };
      }

      const {
        itemCount,
        searchPassMeta,
        searchTerm,
        redirectUrl,
        query = {},
        isNewPage
      } = payload;
      const offset = getOffsetFromQuery(state, payload);

      const diagnostics = getReceiveSearchDiagnostic(payload.diagnostics);

      const { products } = mapAndInsertProducts({
        existingProducts: [],
        products: payload.products
      });

      const facets = Array.isArray(payload.facets) ? [...payload.facets] : [];
      const preserveFacetsForFilterOut =
        !isNewPage && hasFilterReturnedNoResults(itemCount, state)
          ? {
              facets: state.facets,
              range: state.range,
              selectedFacets: filterCurrentPrice(state.oldSelectedFacets)
            }
          : {};

      return {
        ...state,
        facets,
        range: getNewRangeState(initialState, state, facets, query, isNewPage),
        products,
        isLoading: false,
        isNewPage: false,
        redirectUrl,
        selectedFacets: initialState.selectedFacets,
        itemCount,
        offset,
        tileToFocus: {
          ...initialState.tileToFocus
        },
        showLoadMore: shouldShowLoadMore(offset, itemCount),
        showLoadPrevious: shouldShowLoadPrevious(offset),
        diagnostics,
        searchPassMeta,
        searchTerm,
        error: null,
        oldSelectedFacets: [],
        ...preserveFacetsForFilterOut,
        pageSize
      };
    },
    [UPDATE_MULTI_FACET]: (state, { payload }) => ({
      ...state,
      selectedFacets: state.selectedFacets.concat(payload)
    }),
    [UPDATE_RANGE_FACET]: (state, { payload }) => ({
      ...state,
      range: {
        currentprice: {
          ...state.range.currentprice,
          selected: {
            ...state.range.currentprice.selected,
            temp: payload.value
          },
          hasSelectedValues: calculateHasSelectedValuesState(
            payload.value,
            state.range.currentprice
          )
        }
      },
      selectedFacets: state.selectedFacets.concat(
        getPriceRange(state).map(facet => ({
          facetId: facet.id,
          isSelected: true,
          valueId: facet.facetValues[0].id
        }))
      )
    }),
    [UPDATE_SORT_FACET]: (state, { payload }) => ({
      ...state,
      selectedSort: { value: payload }
    }),
    [UPDATE_PERSONALISATION_PARAMS]: (state, { payload }) => ({
      ...state,
      personalisationParams: {
        ...payload
      }
    }),
    [SET_ADDITIONAL_QUERY_PARAMS]: (state, { payload }) => ({
      ...state,
      additionalQueryParams: {
        ...payload
      }
    }),
    [CLEAR_RANGE_FACET]: state => ({
      ...state,
      range: {
        currentprice: {
          ...state.range.currentprice,
          selected: {
            ...state.range.currentprice.selected,
            temp: {
              leftIndicator: state.range.currentprice.min,
              rightIndicator: state.range.currentprice.max
            }
          },
          hasSelectedValues: false
        }
      },
      selectedFacets: state.selectedFacets.concat(
        getPriceRange(state).map(facet => ({
          facetId: facet.id,
          isSelected: false,
          valueId: facet.facetValues[0].id
        }))
      )
    }),
    [CLEAR_SELECTIONS]: state => ({
      ...state,
      selectedSort: {},
      selectedFacets: hasFilterReturnedNoResults(state.itemCount, state)
        ? filterCurrentPrice(state.selectedFacets)
        : initialState.selectedFacets,
      range: hasFilterReturnedNoResults(state.itemCount, state)
        ? state.range
        : {
            currentprice: {
              ...state.range.currentprice,
              selected: {
                ...state.range.currentprice.selected,
                temp: null
              },
              hasSelectedValues: calculateHasSelectedValuesState(
                state.range.currentprice.selected.current,
                state.range.currentprice
              )
            }
          }
    }),
    [SET_RANGE_VALUES]: (state, { payload }) => ({
      ...state,
      selectedFacets: initialState.selectedFacets,
      oldSelectedFacets: state.selectedFacets,
      range: {
        currentprice: {
          ...state.range.currentprice,
          selected: {
            previous: state.range.currentprice.selected.current,
            current: payload.values,
            temp: null
          },
          hasSelectedValues: calculateHasSelectedValuesState(
            payload.values,
            state.range.currentprice
          )
        }
      }
    }),
    [UNDO_RANGE_VALUES]: state => {
      if (state.range.currentprice.selected.previous) {
        return {
          ...state,
          range: {
            currentprice: {
              ...state.range.currentprice,
              selected: {
                current: state.range.currentprice.selected.previous,
                previous: null
              }
            }
          }
        };
      }

      return state;
    },
    [SET_NEW_SEARCH]: state => ({
      ...state,
      isNewSearch: true
    }),
    [CLEAR_NEW_SEARCH]: state => ({
      ...state,
      isNewSearch: false
    }),
    [CLEAR_ON_VIEW_BEACON]: state => ({
      ...state,
      diagnostics: {
        ...state.diagnostics,
        advertisementsAnalytics: {
          ...state.diagnostics.advertisementsAnalytics,
          placementBeacons: {
            ...state.diagnostics.advertisementsAnalytics.placementBeacons,
            onViewBeacon: null
          }
        }
      }
    }),
    [CLEAR_ON_LOAD_BEACON]: state => ({
      ...state,
      diagnostics: {
        ...state.diagnostics,
        advertisementsAnalytics: {
          ...state.diagnostics.advertisementsAnalytics,
          placementBeacons: {
            ...state.diagnostics.advertisementsAnalytics.placementBeacons,
            onLoadBeacon: null
          }
        }
      }
    }),
    [RESTORE_OLD_SELECTED_FACETS]: state => {
      const selectedFacets = [...state.oldSelectedFacets];

      return {
        ...state,
        selectedFacets,
        oldSelectedFacets: []
      };
    },
    [REFRESH_ADVERTISEMENT_BEACONS]: (
      state,
      { payload: { sponsoredProducts, onLoadBeacon, onViewBeacon } }
    ) => ({
      ...state,
      products: sponsoredProducts
        ? state.products.map(product => {
            // if product is sponsored and the variant id in the product advertisement
            // matches the product id in criteo then swap our the beacons
            if (product.isSponsored) {
              const variantId = product.advertisement.variantId;

              const sponsoredProduct = sponsoredProducts.find(
                ({ ProductId }) => ProductId === variantId
              );

              if (sponsoredProduct) {
                return {
                  ...product,
                  advertisement: {
                    ...product.advertisement,
                    onLoadBeacon: sponsoredProduct.OnLoadBeacon,
                    onViewBeacon: sponsoredProduct.OnViewBeacon,
                    onClickBeacon: sponsoredProduct.OnClickBeacon,
                    onBasketChangeBeacon: sponsoredProduct.OnBasketChangeBeacon,
                    onWishlistBeacon: sponsoredProduct.OnWishlistBeacon
                  }
                };
              }
            }

            return product;
          })
        : state.products,
      diagnostics: {
        ...state.diagnostics,
        advertisementsAnalytics: {
          placementBeacons: {
            onLoadBeacon,
            onViewBeacon
          }
        }
      }
    })
  },
  initialState
);
