import { getCountry } from "../state/modules/config/selectors";
import commonConfig from "../config/common";
const { asosKey, webContextKey } = commonConfig.globalKeys;

const getKsdvFromSiteChromeContext = () => {
  if (!CLIENT) {
    return;
  }

  return window[asosKey][webContextKey].getKeyStoreDataversion();
};

export default state =>
  getKsdvFromSiteChromeContext() || getCountry(state).keyStoreDataversion;
