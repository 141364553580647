import { getLocation } from "../../../modules/router/selectors";

const getSearchQuery = state => {
  const {
    query: { q }
  } = getLocation(state);

  if (!q) {
    return "";
  }

  return q.replace(/ /g, "+");
};

export default getSearchQuery;
