import { getAdditionalQueryParams } from "./selectors";
import { setAdditionalQueryParams } from "./actions";

const resetAdditionalQueryParams = (dispatch, state, specificParam = false) => {
  const additionalQueryParams = getAdditionalQueryParams(state);
  if (!specificParam) {
    return dispatch(setAdditionalQueryParams({}));
  } else {
    if (additionalQueryParams?.[specificParam]) {
      delete additionalQueryParams[specificParam];
      dispatch(setAdditionalQueryParams(additionalQueryParams));
    }
  }
};

export default resetAdditionalQueryParams;
