import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import reducer from "./modules";
import { enableReducerHotReload } from "./store.devtools";
import { analyticsMiddleware } from "./middleware/analytics";
import { composeWithDevTools } from "@redux-devtools/extension";

export default (history, state) => {
  const middlewares = [routerMiddleware(history), thunk, analyticsMiddleware];

  const store = createStore(
    reducer(history),
    state,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  enableReducerHotReload(store, history);

  return store;
};
