const customSort = (fn = v => v) => (a, b) => {
  const [tA, tB] = [a, b].map(fn);

  if (tA < tB) {
    return -1;
  }
  if (tA > tB) {
    return 1;
  }

  return 0;
};

export default customSort;
