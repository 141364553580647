import { ERROR_TYPE } from "./constants";

const getErrorType = ({ message, type }) => {
  switch (true) {
    case SERVER || type === ERROR_TYPE.server:
      return ERROR_TYPE.server;
    case "Network Error" === message:
      return ERROR_TYPE.network;
    default:
      return ERROR_TYPE.generic;
  }
};

export default getErrorType;
