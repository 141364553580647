import { SEARCH_GENDERED_SEARCH } from "../../lib/api/constants";
import { setAdditionalQueryParams } from "../../state/modules/search/actions";
import { getAdditionalQueryParams } from "../../state/modules/search/selectors";
import { getFloor } from "../../state/modules/config/selectors";
import { getFloorIdByGender } from "asos-site-chrome-store-presentation";
import { UNISEX_FLOOR } from "../../state/modules/page/constants";
import { getIsSearchPage } from "../../state/modules/page/selectors";
import resetAdditionalQueryParams from "../../state/modules/search/resetAdditionalQueryParams";

export const setupExperiments = (store, decisions) => {
  const { dispatch, getState } = store;
  const state = getState();

  if (decisions?.[SEARCH_GENDERED_SEARCH]?.bucket === "v2") {
    if (getIsSearchPage(state)) {
      const floor = getFloor(state);
      const floorId = getFloorIdByGender(floor);
      let params = getAdditionalQueryParams(state);

      if (floorId) {
        params = { ...params, refine: `floor:${floorId},${UNISEX_FLOOR}` };
        dispatch(setAdditionalQueryParams(params));
      }
    } else {
      resetAdditionalQueryParams(dispatch, state, "refine");
    }
  }
};
