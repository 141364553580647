import { combineReducers } from "redux";
import analytics from "./analytics/reducer";
import config from "./config";
import search from "./search";
import error from "./error";
import layout from "./layout";
import page from "./page";
import brands from "./brands";
import category from "./category";

import { reducer as savedItems } from "./savedItems";
import { reducer as adverts } from "./adverts";
import { reducer as facet } from "./facet/reducer";
import commonConfig from "../../config/common";
import { connectRouter } from "connected-react-router";

export default (history = {}) =>
  combineReducers({
    router: connectRouter(history),
    analytics,
    config,
    search,
    error,
    layout,
    page,
    brands,
    category,
    savedItems,
    facet,
    ...(commonConfig.adverts ? { adverts } : undefined)
  });
