import {
  decisionsContext,
  audienceContext,
  featuresProviderFactory
} from "@asosteam/asos-web-toggle-point-features";
import logger from "../../lib/logger";
import { LOG_LEVEL_WARN } from "../../lib/logger/constants";

const FeaturesProvider = featuresProviderFactory({
  appShortName: "plp",
  logWarning: logger[LOG_LEVEL_WARN]
});

export { decisionsContext, audienceContext, FeaturesProvider };
