import { handleActions } from "redux-actions";
import {
  SHOW_FILTER_OVERLAY,
  HIDE_FILTER_OVERLAY,
  UPDATE_VIEWPORT_CATEGORY,
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
} from "./constants";

const initialState = {
  isFilterOverlayVisible: false,
  viewportCategory: SMALL_VIEWPORT
};

const iNoBounce = CLIENT
  ? require("inobounce")
  : { enable: () => {}, disable: () => {}, isScrollSupported: false };

const validateViewport = (state, viewport) =>
  [SMALL_VIEWPORT, MEDIUM_VIEWPORT, LARGE_VIEWPORT].includes(viewport)
    ? viewport
    : state.viewportCategory;

export default handleActions(
  {
    [SHOW_FILTER_OVERLAY]: state => {
      document.body.classList.add("chrome-hide-overflow");
      if (iNoBounce.isScrollSupported) {
        iNoBounce.enable();
      }

      return { ...state, isFilterOverlayVisible: true };
    },
    [HIDE_FILTER_OVERLAY]: state => {
      document.body.classList.remove("chrome-hide-overflow");
      if (iNoBounce.isScrollSupported) {
        iNoBounce.disable();
      }

      return { ...state, isFilterOverlayVisible: false };
    },
    [UPDATE_VIEWPORT_CATEGORY]: (state, action) => ({
      ...state,
      viewportCategory: validateViewport(state, action.payload.viewportCategory)
    })
  },
  initialState
);
