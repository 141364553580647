import { handleActions } from "redux-actions";
import { RECEIVE_SEARCH } from "../../search/constants";
import { UPDATE_CLIENT_WIDTH } from "../../layout/constants";
import receiveProducts from "./receiveProducts";
import updateValidSlotSizes from "./updateValidSlotSizes";

const initialState = {
  validSlotSizes: []
};

export const reducer = handleActions(
  {
    [RECEIVE_SEARCH]: receiveProducts,
    [UPDATE_CLIENT_WIDTH]: updateValidSlotSizes
  },
  initialState
);
