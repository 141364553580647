import { normalizeRefineParameter } from "./normalizeRefineParameter";

const getNormalizedRefineForSeoImportantFacetConfig = config => {
  const { refinements } = config;
  const facetQuery = refinements
    .map(refinement => `${refinement.facetId}:${refinement.facetValueId}`)
    .join("|");

  return normalizeRefineParameter({ refine: facetQuery });
};

export default getNormalizedRefineForSeoImportantFacetConfig;
