import { TILE_SIZES } from "../../helpers/constants";
const { TILE_SIZE_TWO_BY_TWO } = TILE_SIZES;

const getTreatmentTypeFromHeroMedia = (heroMedia, tileSize) => {
  if (tileSize === TILE_SIZE_TWO_BY_TWO) {
    return "hero";
  }

  return heroMedia.some(({ videoUrl }) => videoUrl)
    ? "image and video"
    : "multiple images";
};

export default getTreatmentTypeFromHeroMedia;
