import PropTypes from "prop-types";

export const withTranslation = Component => {
  const ComposedComponent = (
    props,
    { formatTranslation, formatLink, formatCurrency, formatNumber }
  ) => (
    <Component
      {...props}
      formatTranslation={formatTranslation}
      formatLink={formatLink}
      formatCurrency={formatCurrency}
      formatNumber={formatNumber}
    />
  );

  ComposedComponent.contextTypes = {
    formatTranslation: PropTypes.func.isRequired,
    formatLink: PropTypes.func.isRequired,
    formatCurrency: PropTypes.func.isRequired,
    formatNumber: PropTypes.func.isRequired
  };
  ComposedComponent.displayName = `withTranslation(${
    Component.displayName || Component.name || "Component"
  })`;
  ComposedComponent.WrappedComponent = Component;
  ComposedComponent.initActions = Component.initActions;

  return ComposedComponent;
};

const Translation = ({ formatTranslation, id, values }) => (
  <span>{formatTranslation(id, values)}</span>
);

Translation.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  values: PropTypes.object
};

export default withTranslation(Translation);
