import {
  buildQueryByFacets,
  buildQueryPriceRange
} from "../../../../helpers/url";
import { getLocation } from "../../router/selectors";
import { getFacets, getSort } from "../selectors";
import {
  getNextPriceRange,
  isCurrentPriceRangeSelected,
  getPriceRangeState
} from "../priceRangeSelectors";

const getQueryIfNewSearch = state => {
  const sort = getSort(state);
  const facets = getFacets(state);
  const nextPriceRange = getNextPriceRange(state);

  const newRefine = buildQueryByFacets(facets, {
    ...nextPriceRange,
    hasSelectedValues: isCurrentPriceRangeSelected(state)
  });
  const newPriceRange = buildQueryPriceRange(getPriceRangeState(state));

  const { query } = getLocation(state);

  const shouldUpdate =
    newRefine !== query.refine ||
    newPriceRange !== query.currentpricerange ||
    sort !== query.sort;

  const next = shouldUpdate
    ? {
        query: {
          refine: newRefine,
          currentpricerange: newPriceRange,
          page: undefined,
          sort
        },
        priceRange: nextPriceRange
      }
    : undefined;

  return {
    next,
    shouldUpdate
  };
};

export default getQueryIfNewSearch;
