import { updateLocationQuery } from "../../router/actions";
import { getCanonicalPath, getLocation } from "../../router/selectors";
import {
  clearSelections,
  setNewSearch,
  setNextPriceRangeToCurrent
} from "../actions";
import getQueryIfNewSearch from "./getQueryIfNewSearch";

export default function applyFacets() {
  return (dispatch, getState) => {
    const state = getState();
    const { shouldUpdate, next } = getQueryIfNewSearch(state);

    if (shouldUpdate) {
      const {
        query: { cid }
      } = getLocation(state);

      let pathname;

      if (cid) {
        const stateWithNewQuery = {
          ...state,
          router: {
            locationBeforeTransitions: {
              query: {
                ...next.query,
                cid
              }
            }
          }
        };
        pathname = getCanonicalPath(stateWithNewQuery);
      }

      dispatch(setNewSearch());
      dispatch(
        updateLocationQuery({
          query: next.query,
          pathname
        })
      );
      dispatch(setNextPriceRangeToCurrent(next.priceRange));
    }

    dispatch(clearSelections());
  };
}
