import { createAction } from "redux-actions";
import { ANALYTICS_LOAD_MORE, ANALYTICS_LOAD_PREVIOUS } from "../constants";
import { mapProduct } from "../../search/product/mapAndInsertProducts/mapProduct";

const payloadCreator = ({ response: { products = [] }, page }) => ({
  returnedProductList: products.map(product => mapProduct({ product })),
  page
});

const unaryCreateAction = type => createAction(type, payloadCreator);

const [analyticsLoadMore, analyticsLoadPrevious] = [
  ANALYTICS_LOAD_MORE,
  ANALYTICS_LOAD_PREVIOUS
].map(unaryCreateAction);

export { analyticsLoadMore, analyticsLoadPrevious };
