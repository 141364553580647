import { Route } from "react-router-dom";
import NoBrandPageRoute from "../containers/NoBrandPageRoute";
import SearchPageRoute from "../containers/SearchPageRoute";
import BrandsPageRoute from "../containers/BrandsPageRoute";
import NotFoundErrorPageRoute from "../containers/NotFoundErrorPageRoute";
import CategoryPageRoute from "../containers/CategoryPageRoute";
import {
  BRAND_ERROR_PATH_MATCHER,
  BRAND_UNAVAILABLE_PATH_MATCHER,
  SEARCH_PAGE_PATH_MATCHER,
  BRANDS_PAGE_PATH_MATCHER,
  CATEGORY_PAGE_PATH_MATCHER,
  PAGE_NOT_FOUND_PATH_MATCHER
} from "./constants";
import {
  BRAND_ERROR,
  BRAND_UNAVAILABLE,
  SEARCH_PAGE,
  BRANDS_PAGE,
  CATEGORY_PAGE,
  PAGE_NOT_FOUND
} from "../state/modules/page/constants";

const routes = [
  <Route
    path={BRAND_ERROR_PATH_MATCHER}
    component={NoBrandPageRoute}
    key={BRAND_ERROR}
  />,
  <Route
    path={BRAND_UNAVAILABLE_PATH_MATCHER}
    component={NoBrandPageRoute}
    key={BRAND_UNAVAILABLE}
  />,
  <Route
    path={SEARCH_PAGE_PATH_MATCHER}
    component={SearchPageRoute}
    key={SEARCH_PAGE}
  />,
  <Route
    path={BRANDS_PAGE_PATH_MATCHER}
    component={BrandsPageRoute}
    key={BRANDS_PAGE}
  />,
  <Route
    path={CATEGORY_PAGE_PATH_MATCHER}
    component={CategoryPageRoute}
    key={CATEGORY_PAGE}
  />,
  // This is a catch-all for any requests that make their way to us, but we don't know how to handle.
  <Route
    path={PAGE_NOT_FOUND_PATH_MATCHER}
    component={NotFoundErrorPageRoute}
    key={PAGE_NOT_FOUND}
  />
];

export default routes;
