import { ANCHOR_PRODUCT } from "../constants";

const reducer = {
  [ANCHOR_PRODUCT]: state => ({
    ...state,
    isNewSearch: false
  })
};

export default reducer;
