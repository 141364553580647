import {
  analyticsSmallViewSubmit,
  analyticsAddPendingSmallViewSubmit
} from "../../analytics/actions";
import { getSelectedFacetValuesByFacet } from "../selectors";
import getQueryIfNewSearch from "./getQueryIfNewSearch";
import applyFacets from "./apply";

export default function done(facet, analyticsType) {
  return (dispatch, getState) => {
    const state = getState();
    const { shouldUpdate } = getQueryIfNewSearch(state);

    const selectedFacetValues = facet
      ? getSelectedFacetValuesByFacet(state, facet).filter(
          facetValue => facetValue.isSelected
        )
      : [];

    if (shouldUpdate) {
      dispatch(
        analyticsAddPendingSmallViewSubmit({
          facet,
          analyticsType,
          selectedFacetValues
        })
      );
    } else {
      dispatch(
        analyticsSmallViewSubmit({ facet, analyticsType, selectedFacetValues })
      );
    }

    dispatch(applyFacets());
  };
}
