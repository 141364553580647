const emptyValues = [undefined, "", null];

export const filterEmptyValues = obj =>
  Object.entries(obj).reduce(
    (obj, [key, value]) => ({
      ...obj,
      ...(!emptyValues.includes(value) ? { [key]: value } : undefined)
    }),
    {}
  );
