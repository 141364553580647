import isFacetedNavPage from "../../../../helpers/isFacetedNavPage";

const getFirstFacetedNavRefineFromFacetCombinations = ({
  facetCombinationsToCheck,
  seoImportantFacets
}) =>
  facetCombinationsToCheck
    .map(combination => combination.join("|"))
    .find(refine => isFacetedNavPage({ seoImportantFacets, refine }));

export default getFirstFacetedNavRefineFromFacetCombinations;
