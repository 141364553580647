import getAdvertsSdk from "./getAdvertsSdk";
import getRequestOptions from "./getRequestOptions";
import getRequestParameters from "./getRequestParameters";

const makeCriteoRequest = async ({
  pageNumber,
  organicProductsCount,
  categoryId,
  searchTerm,
  filters,
  sponsoredProductIds,
  isSearchPage,
  isSorted
}) => {
  const { getAdverts } = await getAdvertsSdk();

  const options = getRequestOptions({
    sponsoredProductIds,
    isSearchPage,
    isSorted
  });

  const parameters = getRequestParameters({
    pageNumber,
    organicProductsCount,
    categoryId,
    searchTerm,
    filters,
    sponsoredProductIds,
    isSearchPage,
    isSorted
  });

  return await getAdverts({
    options,
    parameters
  });
};

export default makeCriteoRequest;
