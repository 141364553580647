import { changeMultiFacet, clearRangeFacet } from "../actions";
import { getFacets } from "../selectors";
import { isFacetPriceRange } from "../priceRangeSelectors";
import { isLargeMediumViewPort } from "../viewportHelper";
import applyFacets from "./apply";
import {
  analyticsClearRefinement,
  analyticsPendingClearRefinement
} from "../../analytics/actions";

export default function clear(facetId) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedFacets = getFacets(state);
    const facet = selectedFacets.find(facet => facet.id === facetId);

    if (facet) {
      const selectedFacetValues = facet.facetValues.filter(
        facetValue => facetValue.isSelected
      );

      const facetsToUnselect = selectedFacetValues.map(facetValue => ({
        facetId,
        valueId: facetValue.id,
        isSelected: false
      }));

      dispatch(changeMultiFacet(facetsToUnselect));

      if (isLargeMediumViewPort(state)) {
        if (isFacetPriceRange({ id: facetId })) {
          dispatch(clearRangeFacet());
        }
        dispatch(applyFacets());
        dispatch(
          analyticsPendingClearRefinement({
            facet,
            clearedFacetValues: selectedFacetValues
          })
        );
      } else {
        dispatch(
          analyticsClearRefinement({
            facet,
            clearedFacetValues: selectedFacetValues
          })
        );
        if (isFacetPriceRange({ id: facetId })) {
          dispatch(clearRangeFacet());
        }
      }
    }
  };
}
