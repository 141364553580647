import { getCategoryId } from "../../state/modules/category/selectors";
import {
  getAllAppliedRefinements,
  getSearchTerm,
  getSort
} from "../../state/modules/search/selectors";
import getProductsForPage from "../../state/modules/search/getProductsForPage";
import getPageNumber from "../../state/modules/search/getPageNumber";
import getSponsoredProducts from "../../state/modules/search/getSponsoredProducts";
import getSponsoredProductIds from "../../state/modules/search/getSponsoredProductIds";
import {
  refreshAdvertisementBeacons,
  sendPlacementOnLoadBeacon
} from "../../state/modules/search";
import { getIsSearchPage } from "../../state/modules/page/selectors";
import getFiltersString from "./getFiltersString";
import makeCriteoRequest from "./makeCriteoRequest";
import shouldRefreshBeacons from "./shouldRefreshBeacons";
import shouldAttemptCriteoRequest from "./shouldAttemptCriteoRequest";

const initialiseAdvertsSdk = async ({ dispatch, getState }) => {
  const state = getState();
  const pageNumber = getPageNumber(state);

  const productsCount = getProductsForPage(state, pageNumber).length;
  const sponsoredProductsCount = getSponsoredProducts(state).length;
  const organicProductsCount = productsCount - sponsoredProductsCount;

  const sponsoredProductIds = getSponsoredProductIds(state);
  const isSorted = !!getSort(state);

  const categoryId = getCategoryId(state);
  const searchTerm = getSearchTerm(state);

  if (!shouldAttemptCriteoRequest({ categoryId, searchTerm })) return;

  const {
    products: sponsoredProducts,
    OnLoadBeacon: onLoadBeacon,
    OnViewBeacon: onViewBeacon
  } = await makeCriteoRequest({
    pageNumber,
    organicProductsCount,
    categoryId,
    searchTerm,
    filters: getFiltersString(getAllAppliedRefinements(state)),
    sponsoredProductIds,
    isSearchPage: getIsSearchPage(state),
    isSorted
  });

  if (shouldRefreshBeacons({ sponsoredProductIds, isSorted })) {
    dispatch(
      refreshAdvertisementBeacons({
        sponsoredProducts,
        onLoadBeacon,
        onViewBeacon
      })
    );
    dispatch(sendPlacementOnLoadBeacon());
  }
};

export default initialiseAdvertsSdk;
