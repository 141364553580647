import { handleActions } from "redux-actions";
import { RECEIVE_BRANDS } from "./constants";

const initialState = {};

export default handleActions(
  {
    [RECEIVE_BRANDS]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  initialState
);
