import { getKeyedCookieValue } from "asos-site-chrome-store-presentation";
import getCustomerId from "../client/identity/getCustomerId";
import { buildAppliedRefinement } from "./emitterEvents";
import { TRACKING_CATEGORY_PAGE_TYPE } from "./constants";
import { getLocation } from "../state/modules/router/selectors";
import { getViewportCategory } from "../state/modules/layout/selectors";
import {
  getFacets,
  getPaginationData,
  getItemCount,
  getProducts,
  getRecommendationsAnalytics,
  getSearchMetadata,
  getSearchPassesName,
  getRequestId
} from "../state/modules/search/selectors";
import getAllAppliedRefinements from "../state/modules/search/facets/getAllAppliedRefinements";
import { getError } from "../state/modules/error";
import { getPageName } from "../state/modules/page/selectors";
import {
  getLocale,
  getPlatform,
  getCountry,
  getRowLength,
  getCurrencyCode,
  getCountryCode
} from "../state/modules/config/selectors";
import {
  SEARCH_PAGE,
  CATEGORY_PAGE,
  BRANDS_PAGE
} from "../state/modules/page/constants";
import {
  getCategoryId,
  getCategoryGender,
  getCategoryTitle,
  getCategoryBreadcrumbs,
  getCategoryIsBrand
} from "../state/modules/category";
import { formatCategoryBreadcrumbs } from "../helpers/analytics";
import { isFacetPriceRange } from "../state/modules/search/priceRangeSelectors";
import { options } from "../components/Sort/queryParamsToOptions";
import getTranslations from "../../i18n";
import getChildCategoryID from "./getChildCategoryID";
import getProductTreatment from "../helpers/getProductTreatment";
import { MEDIUM_VIEWPORT } from "../state/modules/layout/constants";

const asosBasketCookieName = "asosbasket";
const shouldAddTreatments = (refinements, viewport) =>
  refinements && viewport !== MEDIUM_VIEWPORT;

export const getClientPageLoadData = async state => {
  const customerId = await getCustomerId();
  const { storeCode, defaultLanguage: language } = getCountry(state);
  const paginationData = getPaginationData(state);
  const {
    personalisationStatus,
    numberOfItems: numberOfRecommendedItems,
    items: recommendedProductsList
  } = getRecommendationsAnalytics(state);
  let products = getProducts(state);

  const refinements = getAllAppliedRefinements(state);

  const addTreatments = shouldAddTreatments(
    !refinements.length,
    getViewportCategory(state)
  );

  if (addTreatments) {
    products = products.map((product, productIndex) => {
      const treatment = getProductTreatment({ product, productIndex }, state);

      return {
        ...product,
        featuredProductTileType: treatment?.treatmentType
      };
    });
  }

  return {
    customerID: customerId ? customerId : -1,
    platform: getPlatform(state),
    country: getCountryCode(state),
    store: storeCode.toLowerCase(),
    language,
    currency: getCurrencyCode(state),
    viewportCategory: getViewportCategory(state),
    returnedProductList: products,
    lastPage: !paginationData.meta.canLoadMore,
    pageGridSize: getRowLength(state),
    pageNumber: paginationData.page.initial,
    pageSize: products.length,
    totalStyles: getItemCount(state),
    personalisationStatus,
    numberOfRecommendedItems,
    recommendedProductsList,
    totalBagItems: getKeyedCookieValue(asosBasketCookieName, "basketitemcount"),
    totalBagValueLocalCurrency: getKeyedCookieValue(
      asosBasketCookieName,
      "basketitemtotalretailprice"
    ),
    ...getSharedAnalyticsObject(state)
  };
};

const getSortAnalyticsObject = async state => {
  const currentSort = getLocation(state).query.sort;
  const sortOptions = options(getPageName(state));
  const selectedOption = sortOptions.find(
    option => option.value === currentSort
  );

  const translations = await getTranslations(getLocale(state));

  const optionDisplayText = translations[selectedOption.label];

  return {
    sortType: currentSort,
    sortName: optionDisplayText
  };
};

export const getAppliedRefinementsData = async state => {
  const facetsWithSelectedValues = getFacets(state).filter(
    facet => facet.hasSelectedValues && !isFacetPriceRange(facet)
  );

  return {
    refinements: {
      context: "refinements:applied",
      listAppliedRefinements: facetsWithSelectedValues.map(facet =>
        buildAppliedRefinement(
          state,
          facet,
          facet.facetValues.filter(facetValue => facetValue.isSelected)
        )
      ),
      sort: await getSortAnalyticsObject(state)
    }
  };
};

export const getPageSpecificAnalyticsData = state => {
  const location = getLocation(state);
  const pageName = getPageName(state);

  switch (pageName) {
    case SEARCH_PAGE:
      return {
        floor: "search",
        pageType: "search",
        searchTerm:
          typeof location.query.q === "string"
            ? location.query.q.toLowerCase()
            : "",
        correctedSearchTerm: getSearchMetadata(state).alternateSearchTerms,
        searchType: getSearchType(location),
        searchPasses: getSearchPassesName(state),
        failedSearchType: getFailedSearchType(state),
        breadcrumbs: "search%$all"
      };

    case CATEGORY_PAGE:
      return {
        breadcrumbs: formatCategoryBreadcrumbs(getCategoryBreadcrumbs(state)),
        floor: getCategoryGender(state),
        categoryID: getCategoryId(state),
        pageName: getCategoryTitle(state),
        pageType: TRACKING_CATEGORY_PAGE_TYPE,
        isBrand: getCategoryIsBrand(state)
      };
    case BRANDS_PAGE:
      return {
        breadcrumbs: formatCategoryBreadcrumbs(getCategoryBreadcrumbs(state)),
        floor: getCategoryGender(state),
        categoryID: getCategoryId(state)
      };
    default: {
      return {};
    }
  }
};

const getSearchType = location => {
  switch ("true") {
    case location.query.RecentSearchesUsed:
      return "recent";
    case location.query.SearchSuggestUsed:
      return "suggested";
    default:
      return "free text";
  }
};

const getFailedSearchType = state => {
  if (getError(state)) {
    return "failed by error";
  } else if (getItemCount(state) === 0) {
    return "zero results";
  }

  return "";
};

export const getSharedAnalyticsObject = state => ({
  requestId: getRequestId(state),
  childCategoryID: getChildCategoryID(state)
});
