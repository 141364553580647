import {
  getPriceRangeFromQueryParam,
  getSelectedPriceRangeFromRefineParam
} from "./priceRangeUrl";
import {
  getMinMaxFromApiResponse,
  isPriceRangeSelected,
  isFacetPriceRange
} from "./priceRangeSelectors";

export const getCurrentSelectedPriceRange = (
  initialState,
  state,
  hasSelectedValues,
  minMaxBounds,
  refine,
  isNewPage
) => {
  let currentSelectedPriceRange = {};
  if (
    state.range.currentprice.selected.current ===
      initialState.range.currentprice.selected.current ||
    isNewPage
  ) {
    //the current selected state is in its initialState
    if (hasSelectedValues) {
      //page was loaded with selected values in url
      const [
        leftIndicator,
        rightIndicator
      ] = getSelectedPriceRangeFromRefineParam(refine);

      currentSelectedPriceRange = {
        leftIndicator,
        rightIndicator
      };
    } else {
      //the customer has not selected any price range use the range bounds
      currentSelectedPriceRange = {
        leftIndicator: minMaxBounds.min,
        rightIndicator: minMaxBounds.max
      };
    }
  } else {
    // state already has value maintain it
    currentSelectedPriceRange = state.range.currentprice.selected.current;
  }

  return currentSelectedPriceRange;
};

const getBound = ({ hasSelected, boundType, queryMinMax, apiMinMax }) => {
  const { [boundType]: query } = queryMinMax;
  const { [boundType]: api } = apiMinMax;

  return hasSelected & Number.isInteger(query) ? query : api;
};

const getBounds = ({ currentpricerange, hasSelectedValues, facets }) => {
  const otherFacetsHasSelectedValue = facets.some(
    facet => !isFacetPriceRange(facet) && facet.hasSelectedValues
  );

  const hasSelected = otherFacetsHasSelectedValue || hasSelectedValues;
  const queryMinMax = getPriceRangeFromQueryParam(currentpricerange);
  const apiMinMax = getMinMaxFromApiResponse({
    facets
  });

  return ["min", "max"].reduce(
    (bounds, boundType) => ({
      ...bounds,
      [boundType]: getBound({ hasSelected, boundType, queryMinMax, apiMinMax })
    }),
    {}
  );
};

export const getNewRangeState = (
  initialState,
  state,
  facets,
  query,
  isNewPage
) => {
  const { currentpricerange, refine } = query;
  const hasSelectedValues = isPriceRangeSelected({ facets });

  const minMaxBounds = getBounds({
    currentpricerange,
    hasSelectedValues,
    facets
  });

  return {
    currentprice: {
      ...state.range.currentprice,
      selected: {
        ...state.range.currentprice.selected,
        current: getCurrentSelectedPriceRange(
          initialState,
          state,
          hasSelectedValues,
          minMaxBounds,
          refine,
          isNewPage
        )
      },
      ...minMaxBounds,
      hasSelectedValues
    }
  };
};
