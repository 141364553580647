import { createAction } from "redux-actions";
import {
  ANALYTICS_CLEAR_ALL_REFINEMENTS,
  ANALYTICS_CLEAR_REFINEMENT,
  ANALYTICS_REFINEMENTS_TOGGLED,
  ANALYTICS_REFINEMENTS_SELECT,
  ANALYTICS_SMALL_VIEW_SUBMIT,
  ANALYTICS_REFINEMENTS_SORT,
  ANALYTICS_REMOVE_PENDING_EVENT,
  ANALYTICS_SELECT_ALL,
  ANALYTICS_ADD_PENDING_EVENT
} from "../constants";

const unaryCreateAction = type =>
  createAction(ANALYTICS_ADD_PENDING_EVENT, refinementPendingPayload => ({
    refinementPendingActionType: type,
    refinementPendingPayload
  }));

const refinements = [
  ANALYTICS_CLEAR_ALL_REFINEMENTS,
  ANALYTICS_CLEAR_REFINEMENT,
  ANALYTICS_REFINEMENTS_TOGGLED,
  ANALYTICS_REFINEMENTS_SELECT,
  ANALYTICS_SMALL_VIEW_SUBMIT,
  ANALYTICS_REFINEMENTS_SORT,
  ANALYTICS_REMOVE_PENDING_EVENT,
  ANALYTICS_SELECT_ALL
].map(unaryCreateAction);

const [
  analyticsPendingClearAllRefinements,
  analyticsPendingClearRefinement,
  analyticsAddPendingRefinementsToggled,
  analyticsAddPendingRefinementSelect,
  analyticsAddPendingSmallViewSubmit,
  analyticsAddPendingSortRefinement,
  analyticsRemovePendingEvent,
  analyticsAddPendingSelectAll
] = refinements;

export {
  analyticsPendingClearAllRefinements,
  analyticsPendingClearRefinement,
  analyticsAddPendingRefinementsToggled,
  analyticsAddPendingRefinementSelect,
  analyticsAddPendingSmallViewSubmit,
  analyticsAddPendingSortRefinement,
  analyticsRemovePendingEvent,
  analyticsAddPendingSelectAll
};
