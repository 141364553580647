import { normalizeRefineParameter } from "./normalizeRefineParameter";
import getNormalizedRefineForSeoImportantFacetConfig from "./getNormalizedRefineForSeoImportantFacetConfig";

const isFacetedNavPage = ({ seoImportantFacets, refine }) => {
  if (!refine || !seoImportantFacets) {
    return false;
  }

  const normalizedRefine = normalizeRefineParameter({ refine });

  const facetRefineMatchesNormalizedRefine = facet => {
    const normalizedQuery = getNormalizedRefineForSeoImportantFacetConfig(
      facet
    );

    return normalizedQuery === normalizedRefine;
  };

  return seoImportantFacets.some(facetRefineMatchesNormalizedRefine);
};

export default isFacetedNavPage;
