import {
  analyticsRefinementsSelect,
  analyticsRefinementsDeselect
} from "./analyticsSimpleRefinements";

const analyticsRefinementsToggled = refinements => dispatch => {
  refinements.forEach(({ facet, facetValue, isSelected }) => {
    if (isSelected) {
      dispatch(analyticsRefinementsSelect({ facet, facetValue }));
    } else {
      dispatch(
        analyticsRefinementsDeselect({
          facet,
          facetValue
        })
      );
    }
  });
};

export { analyticsRefinementsToggled as default };
