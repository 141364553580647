const getAdditionalImages = ({ images, imagesConfig }) => {
  const additionalImageUrls = [];

  if (!imagesConfig) {
    return images.slice(1);
  }

  imagesConfig.slice(1).forEach(imageIndex => {
    if (images[imageIndex - 1]) {
      additionalImageUrls.push(images[imageIndex - 1]);
    }
  });

  if (additionalImageUrls.length) {
    return additionalImageUrls;
  }

  return null;
};

export default getAdditionalImages;
