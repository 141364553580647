import { replace } from "connected-react-router";

import { getLocation } from "./selectors";
import { getNewParams } from "./getNewParams";

export const buildLocationQuery = ({
  query: params,
  pathname = getLocation().pathname,
  hash,
  preserve
}) => {
  const newParams = getNewParams({ params, preserve });
  delete newParams.affid;
  const { refine, q, ...rest } = newParams;
  const queryString = `${new URLSearchParams({ ...rest }).toString()}`;
  const refineString = refine
    ? `&${new URLSearchParams({ refine }).toString()}`
    : "";
  const searchQueryString = q
    ? `?q=${encodeURIComponent(q)}&${queryString}`
    : `?${queryString}`;

  const location = {
    pathname,
    search: `${searchQueryString}${decodeURIComponent(refineString)}`,
    query: newParams
  };

  if (hash) {
    location.hash = `#${hash}`;
  }

  return location;
};

export const updateLocationQuery = ({
  query,
  pathname,
  hash,
  preserve = true
}) => replace(buildLocationQuery({ query, pathname, hash, preserve }));

export default updateLocationQuery;
