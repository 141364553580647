import { analyticsPageLoad } from "../state/modules/analytics/actions";
import commonConfig from "../config/common";

const {
  globalKeys: { asosKey, eventBusKey }
} = commonConfig;

const setupAdobe = ({ dispatch }) => {
  const eventBus = window[asosKey][eventBusKey];
  eventBus.addListener("analytics-ready", () => {
    dispatch(analyticsPageLoad());
  });
};

export default setupAdobe;
