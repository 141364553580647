const searchRedirectUrl = ({ response }) => {
  const { redirectUrl } = response;

  if (redirectUrl) {
    window.location.replace(redirectUrl);

    return true;
  }

  return false;
};

export default searchRedirectUrl;
