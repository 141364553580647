import PropTypes from "prop-types";
import styles from "./ErrorMessage.css";
import { withTranslation } from "../translation";
import Icon from "../Icon";

export const ErrorMessage = ({ status, formatTranslation }) => {
  const errorType =
    status === 404
      ? {
          header: "plp_web_errors_not_found_header",
          details: "plp_web_errors_not_found_details"
        }
      : {
          header: "plp_web_errors_generic_error_header",
          details: "plp_web_errors_generic_error_details"
        };

  return (
    <div id="pageLoadError" className={styles.errorMessage}>
      <Icon icon="errorEmoji" className={styles.emoji} />
      <h1 className={styles.title}>{formatTranslation(errorType.header)}</h1>
      <p className={styles.message}>{formatTranslation(errorType.details)}</p>
      <button
        onClick={() => window.location.reload()}
        className={styles.button}
      >
        {formatTranslation("plp_web_errors_retry")}
      </button>
    </div>
  );
};

ErrorMessage.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  formatLink: PropTypes.func.isRequired,
  status: PropTypes.number
};

ErrorMessage.displayName = "ErrorMessage";

export default withTranslation(ErrorMessage);
