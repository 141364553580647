const dictionaryStringToObject = ({
  dictionaryString,
  entriesSeparator,
  keyValueSeparator
}) =>
  dictionaryString.split(entriesSeparator).reduce((acc, entry) => {
    const [key, value] = entry.split(keyValueSeparator);
    acc[key] = value;

    return acc;
  }, {});

export default dictionaryStringToObject;
