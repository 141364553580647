import { removeScrollToQueryRegex } from "./constants";

const removeScrollToQuery = history => {
  const location = {
    ...history.location,
    search: history.location.search.replace(removeScrollToQueryRegex, "")
  };

  history.replace(location);
};

export default removeScrollToQuery;
