import { ERROR_TYPE } from "../error/constants";

export const getError = state => state.error;

export const isErrorOfTypeServer = state => {
  const error = getError(state);

  return (
    error !== null &&
    typeof error.type !== "undefined" &&
    error.type === ERROR_TYPE.server
  );
};
