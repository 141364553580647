import "preact/devtools";
import "./setWebpackPublicPath";
import iNoBounce from "inobounce";
import "./global.css";
import anchorToProduct from "./anchorToProduct";
import setupStore from "./setupStore";
import setupAnalytics from "./setupAnalytics";
import bootstrapReact from "./bootstrapReact";

iNoBounce.disable();
anchorToProduct();
const store = setupStore();
setupAnalytics(store);
bootstrapReact(store);
