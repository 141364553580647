import { UPDATE_PROMO_ONBOARD_DISMISS } from "./constants";

const reducer = {
  [UPDATE_PROMO_ONBOARD_DISMISS]: (state, action) => ({
    ...state,
    promoOnboardDismissed: action.payload.promoOnboardDismissed
  })
};

export default reducer;
