import { getOffset } from "../../state/modules/search/selectors";
import { getCategoryTreatments } from "../../state/modules/category";
import getProductTreatmentDefinition from "../../state/modules/search/productTile/getProductTreatmentDefinition";

const getTreatmentDefinition = (productIndex, state) => {
  const lowerOffset = getOffset(state).lower;
  const categoryTreatments = getCategoryTreatments(state);
  const absoluteProductIndex = productIndex + lowerOffset;

  return getProductTreatmentDefinition({
    treatments: categoryTreatments,
    productPositionOneBased: absoluteProductIndex + 1
  });
};

export default getTreatmentDefinition;
