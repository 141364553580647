import {
  SearchApiError,
  CategoryApiError,
  NavigationApiError,
  FacetApiError
} from "./apiErrors";

import {
  SEARCH_REQUEST,
  SEARCH_CATEGORY_REQUEST,
  SEARCH_CURATIONS_REQUEST,
  CATEGORY_DETAILS_REQUEST,
  BRANDS_REQUEST,
  CATEGORY_REQUEST,
  FACET_REQUEST,
  CURATED_CATEGORY_REQUEST
} from "./constants";

const requestTypeToErrorMap = {
  [SEARCH_REQUEST]: SearchApiError,
  [SEARCH_CATEGORY_REQUEST]: SearchApiError,
  [SEARCH_CURATIONS_REQUEST]: SearchApiError,
  [CATEGORY_REQUEST]: CategoryApiError,
  [CATEGORY_DETAILS_REQUEST]: CategoryApiError,
  [CURATED_CATEGORY_REQUEST]: CategoryApiError,
  [BRANDS_REQUEST]: NavigationApiError,
  [FACET_REQUEST]: FacetApiError
};

const handleErrors = requestType => response =>
  new Promise((resolve, reject) => {
    const {
      config: { url },
      data,
      status,
      statusText
    } = response;
    if (status >= 200 && status < 300) {
      resolve(response);
    } else {
      const errorType = requestTypeToErrorMap[requestType];
      const error = new errorType(statusText, {
        status,
        statusText,
        url,
        responseText: JSON.stringify(data)
      });
      reject(error);
    }
  });

export default handleErrors;
