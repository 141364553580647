import { getLocation } from "./selectors";
import { filterEmptyValues } from "./filterEmptyValues";

const merge = params => {
  const existingParams = getLocation().query;

  return Object.assign({}, existingParams, params);
};

export const getNewParams = ({ params, preserve }) =>
  filterEmptyValues(preserve ? merge(params) : params);
