const filterOutUnimportantFacets = ({ facets, seoImportantFacets }) =>
  facets.filter(facetString => {
    const [facetName] = facetString.split(":");

    return seoImportantFacets.some(seoImportantFacetsEntry =>
      seoImportantFacetsEntry.refinements.some(
        ({ facetId }) => facetId === facetName
      )
    );
  });

export default filterOutUnimportantFacets;
