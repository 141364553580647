import getMainImage from "./getMainImage";
import getAdditionalImages from "./getAdditionalImages";

const getHeroMedia = ({
  image,
  additionalImageUrls,
  videoUrl,
  mediaConfig
}) => {
  const images = [image, ...(additionalImageUrls || [])];
  const { mediaType, images: imagesConfig } = mediaConfig;

  const outputVideoUrl = mediaType === "video" ? videoUrl : null;
  const showVideo = !!outputVideoUrl;

  return {
    videoUrl: outputVideoUrl,
    image: getMainImage({ images, imagesConfig }),
    additionalImageUrls:
      images.length > 1
        ? getAdditionalImages({
            images,
            imagesConfig
          })
        : null,
    showVideo
  };
};

export default getHeroMedia;
