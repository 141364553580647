import { createAction } from "redux-actions";
import {
  SET_PAGE_NAME,
  SET_PAGE_LOADING,
  SET_IS_NEW_PAGE,
  SET_IS_SERVER_RENDERED
} from "./constants";

export const setPageName = createAction(SET_PAGE_NAME, name => name);
export const setPageLoading = createAction(SET_PAGE_LOADING, value => value);
export const setIsNewPage = createAction(SET_IS_NEW_PAGE, value => value);
export const setIsServerRendered = createAction(
  SET_IS_SERVER_RENDERED,
  value => value
);
