import getCategoryId from "./getCategoryId";
import { normalizeRefineParameter } from "../../../../helpers/normalizeRefineParameter";
import { getQuery } from "../../search/facets/getAllAppliedRefinements";
import getNormalizedRefineForSeoImportantFacetConfig from "../../../../helpers/getNormalizedRefineForSeoImportantFacetConfig";

export default state => {
  const catId = getCategoryId(state);
  if (!catId) {
    return null;
  }

  const {
    category: {
      details: { seoImportantFacets }
    }
  } = state;
  const { refine } = getQuery(state);

  if (!seoImportantFacets) {
    return [];
  }

  const normalizedRefine = normalizeRefineParameter({ refine });

  const links = seoImportantFacets.map(facet => {
    const { title, path } = facet;
    const normalizedQuery = getNormalizedRefineForSeoImportantFacetConfig(
      facet
    );

    if (normalizedQuery === normalizedRefine) {
      return;
    }

    return {
      friendlyName: title,
      linkUrl: `${path}?cid=${catId}&refine=${normalizedQuery}`
    };
  });

  return links.filter(Boolean);
};
