import mapColourEnrichmentsToSwatchesMap from "./mapColourEnrichmentsToSwatchesMap";

const receiveColourEnrichment = (state, { payload }) => {
  const swatchesMap = mapColourEnrichmentsToSwatchesMap({
    enrichments: payload.items[0].facetValues
  });

  return {
    ...state,
    swatches: swatchesMap
  };
};

export default receiveColourEnrichment;
