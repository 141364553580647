import commonConfig from "../../config/common";
import { useSelector } from "react-redux";
import { getStoreCode } from "../../state/modules/config/selectors";

const useConfigByStore = ({ configKey }) => {
  const key = commonConfig[configKey];
  const storeCode = useSelector(getStoreCode);

  return key.includes(storeCode);
};

export default useConfigByStore;
