export const mapPrice = (price = { current: {} }) => {
  if (price.isOutletPrice) {
    return price.rrp.value;
  }

  if (price.isMarkedDown) {
    return price.previous.value;
  }

  return price.current.value;
};

export const mapReducedPrice = (price = {}) =>
  price.isOutletPrice || price.isMarkedDown ? price.current.value : null;
