import dictionaryStringToObject from "../../../helpers/dictionaryStringToObject";

const makeParamsApiFriendly = params => {
  const { refine, ...paramsWithoutRefine } = params;

  if (!refine) {
    return params;
  }

  const { currentprice, ...separatedRefineParams } = dictionaryStringToObject({
    dictionaryString: refine,
    entriesSeparator: "|",
    keyValueSeparator: ":"
  });

  const currentPriceMinMax = currentprice
    ? convertCurrentPriceRefine(currentprice)
    : {};

  return {
    ...paramsWithoutRefine,
    ...separatedRefineParams,
    ...currentPriceMinMax
  };
};

const convertCurrentPriceRefine = priceRangeString => {
  const pricesplit = priceRangeString.split("<");

  return { priceMin: pricesplit[0], priceMax: pricesplit[1] };
};

export default makeParamsApiFriendly;
