export default state => {
  const { details } = state.category;

  if (!details) {
    return null;
  }

  const { friendlyName: Label, id: CategoryId } = details;

  return {
    Label,
    CategoryId
  };
};
