import getOneProductRedirectUrl from "../../state/modules/search/getOneProductRedirectUrl";

const onlyOneProduct = ({ response, state }) => {
  const { facets, products } = response;

  const futureState = {
    ...state,
    search: {
      ...state.search,
      facets,
      products
    }
  };

  const redirectUrl = getOneProductRedirectUrl(futureState);

  if (redirectUrl) {
    window.location.replace(redirectUrl);

    return true;
  }

  return false;
};

export default onlyOneProduct;
