class ApiError extends Error {
  constructor(message, fetchMetadata = {}) {
    super(message);

    this.name = this.constructor.name;
    this.message = message;
    this.stack = new Error().stack;
    this.status = fetchMetadata.status;
    this.statusText = fetchMetadata.statusText;
    this.url = fetchMetadata.url;
    this.responseText = fetchMetadata.responseText;
  }
}

export class SearchApiError extends ApiError {
  constructor(message, fetchMetadata) {
    super(message, fetchMetadata);

    this.name = "SearchApiError";
    this.message = message || "Search Api Error";
  }
}

export class CategoryApiError extends ApiError {
  constructor(message, fetchMetadata) {
    super(message, fetchMetadata);

    this.name = "CategoryApiError";
    this.message = message || "Category Api Error";
  }
}

export class NavigationApiError extends ApiError {
  constructor(message, fetchMetadata) {
    super(message, fetchMetadata);

    this.name = "NavigationApiError";
    this.message = message || "Navigation Api Error";
  }
}

export class FacetApiError extends ApiError {
  constructor(message, fetchMetadata) {
    super(message, fetchMetadata);

    this.name = "FacetApiError";
    this.message = message || "Facet Api Error";
  }
}
