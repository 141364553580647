import commonConfig from "../../config/common";
import { useSelector } from "react-redux";
import { getCountryCode } from "../../state/modules/config/selectors";

const useConfigByCountry = ({ configKey }) => {
  const key = commonConfig[configKey];
  const countryCode = useSelector(getCountryCode);

  return key.includes(countryCode);
};

export default useConfigByCountry;
