import wasFirstPageLoadedMostRecently from "../state/modules/search/wasFirstPageLoadedMostRecently";
import { getItemCount } from "../state/modules/search";
import getFaceAndBodyCarouselCategory from "../components/ProductListingPage/__variants__/plp-face-and-body-carousel/v2/getCarouselCategory";
import getKylieCarouselCategory from "../components/ProductListingPage/__variants__/plp-kylie-carousel/v2/getCarouselCategory";
import { MINIMUM_SEARCH_RESULTS_FOR_CAROUSEL } from "../components/UpSellCarousel/constants";

const getChildCategoryID = state => {
  const itemCount = getItemCount(state);

  if (
    !wasFirstPageLoadedMostRecently(state) ||
    itemCount < MINIMUM_SEARCH_RESULTS_FOR_CAROUSEL
  ) {
    return;
  }

  const { carouselCatId } =
    getKylieCarouselCategory(state) ||
    getFaceAndBodyCarouselCategory(state) ||
    {};

  return carouselCatId;
};

export default getChildCategoryID;
