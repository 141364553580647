import { config } from "../config/client";

let configuration;
const getClientConfigValue = key => {
  if (!configuration) {
    const {
      globalKeys: { asosKey, plpKey, configKey }
    } = config;
    configuration = window[asosKey][plpKey][configKey];
    delete window[asosKey][plpKey][configKey];
  }

  return configuration[key];
};

export default getClientConfigValue;
