export const getPriceRangeFromQueryParam = (param = "") => {
  if (param === "") return {};

  return param
    .split("-")
    .map(Number)
    .reduce((min, max) => ({ min, max }));
};

export const getSelectedPriceRangeFromRefineParam = (
  refineParam = "",
  facetId = "currentprice"
) => {
  const [, ...minMax] =
    refineParam.match(new RegExp(`${facetId}:([0-9]+)<([0-9]+)`)) || [];

  return minMax.map(Number);
};
