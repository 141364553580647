import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Polyglot from "node-polyglot";
import { formatPrice, formatValue } from "asos-web-currency-formatter";
import {
  buildUrl,
  getLocale,
  getStoreCode,
  getStoreUrl,
  getCurrencyCode
} from "../../state/modules/config/selectors";

export const buildNumberFormatterFn = locale => number => {
  const formatAsInteger = true;

  return formatValue(number, locale, formatAsInteger);
};

export const buildTranslationFormatterFn = (locale, phrases) => {
  const polyglot = new Polyglot({
    locale,
    phrases
  });

  return (...args) => polyglot.t(...args);
};

export const buildCurrencyFormatterFn = (locale, currency) => (
  value,
  formatAsInteger
) => formatPrice(value, locale, currency, formatAsInteger);

export class LocaleProvider extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    storeCode: PropTypes.string.isRequired,
    storeUrl: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    buildTranslationFormatter: PropTypes.func,
    buildNumberFormatter: PropTypes.func,
    buildCurrencyFormatter: PropTypes.func
  };

  static defaultProps = {
    buildTranslationFormatter: buildTranslationFormatterFn,
    buildNumberFormatter: buildNumberFormatterFn,
    buildCurrencyFormatter: buildCurrencyFormatterFn
  };

  static childContextTypes = {
    formatLink: PropTypes.func.isRequired,
    formatNumber: PropTypes.func.isRequired,
    formatTranslation: PropTypes.func.isRequired,
    formatCurrency: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const {
      buildTranslationFormatter,
      buildNumberFormatter,
      buildCurrencyFormatter,
      locale,
      storeCode,
      translations,
      currency,
      storeUrl
    } = this.props;

    this.formatLink = buildUrl.bind(null, { storeUrl, storeCode });
    this.formatTranslation = buildTranslationFormatter(locale, translations);
    this.formatNumber = buildNumberFormatter(locale);
    this.formatCurrency = buildCurrencyFormatter(locale, currency);
  }

  getChildContext() {
    return {
      formatLink: this.formatLink,
      formatNumber: this.formatNumber,
      formatTranslation: this.formatTranslation,
      formatCurrency: this.formatCurrency
    };
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  locale: getLocale(state),
  storeCode: getStoreCode(state),
  currency: getCurrencyCode(state),
  storeUrl: getStoreUrl(state)
});

export default connect(mapStateToProps)(LocaleProvider);
