import emitterEvents from "./emitterEvents";
import commonConfig from "../config/common";

const {
  globalKeys: { asosKey, eventBusKey }
} = commonConfig;

export const emit = async (action, store) => {
  if (action && Object.keys(emitterEvents).includes(action.type)) {
    window[asosKey][eventBusKey].emit(
      ...(await emitterEvents[action.type](action, store.getState()))
    );
  }
};
