import commonConfig from "../../../../config/common";

const updateValidSlotSizes = (state, { payload: { clientWidth } }) => {
  const {
    adverts: { sizeMappings }
  } = commonConfig;

  const validSlotSizes = sizeMappings.find(
    ({ viewPortSize: [width] }) => width <= clientWidth
  ).slotSizes;

  return {
    ...state,
    validSlotSizes
  };
};

export default updateValidSlotSizes;
