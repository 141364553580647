import { handleActions } from "redux-actions";
import {
  SET_FLOOR,
  SET_LOCALE,
  SET_COUNTRY,
  SET_PLATFORM,
  SET_CURRENCY,
  SET_STORE_URL,
  SET_SIZE_SCHEMA,
  SET_GEOLOCATION
} from "./constants";

// TODO: grab this from config.countries
export const initialState = {
  locale: "en-GB",
  country: {
    siteId: 1,
    storeCode: "COM",
    defaultLanguage: "en-GB",
    defaultSizeSchema: "UK",
    defaultCurrency: "GBP",
    url: "http://www.asos.com/",
    countryCode: "GB",
    imageUrl: "/assets/storesa/images/flags/gb.png",
    name: "United Kingdom",
    majorCountry: null,
    isDefaultCountry: true,
    keyStoreDataVersion: 1,
    geolocation: {}
  },
  platform: "mobile"
};

export default handleActions(
  {
    [SET_FLOOR]: (state, action) => ({
      ...state,
      floor: action.payload
    }),
    [SET_LOCALE]: (state, action) => ({
      ...state,
      locale: action.payload
    }),
    [SET_PLATFORM]: (state, action) => ({
      ...state,
      platform: action.payload
    }),
    [SET_SIZE_SCHEMA]: (state, action) => ({
      ...state,
      sizeSchema: action.payload
    }),
    [SET_COUNTRY]: (state, action) => ({
      ...state,
      country: action.payload
    }),
    [SET_CURRENCY]: (state, action) => ({
      ...state,
      currency: action.payload
    }),
    [SET_STORE_URL]: (state, action) => ({
      ...state,
      storeUrl: action.payload
    }),
    [SET_GEOLOCATION]: (state, action) => ({
      ...state,
      geolocation: action.payload
    })
  },
  initialState
);
