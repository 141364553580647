import {
  buildUrl,
  getStoreCode,
  getStoreUrl
} from "../../../modules/config/selectors";
import { getProducts } from "../../../modules/search/selectors";

const buildProductUrl = state => {
  const products = getProducts(state);

  return buildUrl(
    { storeCode: getStoreCode(state), storeUrl: getStoreUrl(state) },
    products[0].url
  );
};

export default buildProductUrl;
