export const REQUEST_SEARCH = "REQUEST_SEARCH";
export const RECEIVE_SEARCH = "RECEIVE_SEARCH";

export const UPDATE_MULTI_FACET = "UPDATE_MULTI_FACET";
export const UPDATE_RANGE_FACET = "UPDATE_RANGE_FACET";
export const UPDATE_SORT_FACET = "UPDATE_SORT_FACET";
export const UPDATE_PERSONALISATION_PARAMS = "UPDATE_PERSONALISATION_PARAMS";
export const SET_ADDITIONAL_QUERY_PARAMS = "SET_ADDITIONAL_QUERY_PARAMS";
export const SET_RANGE_VALUES = "SET_RANGE_VALUES";
export const UNDO_RANGE_VALUES = "UNDO_RANGE_VALUES";
export const CLEAR_RANGE_FACET = "CLEAR_RANGE_FACET";
export const CLEAR_SELECTIONS = "CLEAR_SELECTIONS";

export const MORE_PRODUCTS_LOADED = "MORE_PRODUCTS_LOADED";
export const PREVIOUS_PRODUCTS_LOADED = "PREVIOUS_PRODUCTS_LOADED";
export const LOADING_MORE_PRODUCTS = "LOADING_MORE_PRODUCTS";
export const LOADING_PREVIOUS_PRODUCTS = "LOADING_PREVIOUS_PRODUCTS";
export const SET_NEW_SEARCH = "SET_NEW_SEARCH";
export const CLEAR_NEW_SEARCH = "CLEAR_NEW_SEARCH";
export const RESTORE_OLD_SELECTED_FACETS = "RESTORE_OLD_SELECTED_FACETS";

export const MOBILE_DONE = "MOBILE_DONE";
export const MOBILE_VIEW_ITEMS = "MOBILE_VIEW_ITEMS";

export const ANCHOR_PRODUCT = "ANCHOR_PRODUCT";

export const COLOUR_FACET_GROUP = "SupplierColour";

export const MORE_COLOURS = "MoreColours";
export const MORE_COLOURS_FACET = "MoreColoursFacet";
export const MIX_MATCH = "MixMatch";
export const MIX_MATCH_GROUP = "MixMatchGroup";
export const MIX_MATCH_PRODUCT = "MixMatchProduct";
export const PRODUCT = "Product";

export const ADVERTISING_PARTNER_QUERY = "advertisementsPartnerId";
export const ADVERTISING_CONSENT_QUERY = "advertisementsOptInConsent";
export const ADVERTISING_VISITOR_QUERY = "advertisementsVisitorId";

export const FEATURES_ID_COOKIE = "featuresId";

export const CLEAR_ON_LOAD_BEACON = "CLEAR_ON_LOAD_BEACON";
export const CLEAR_ON_VIEW_BEACON = "CLEAR_ON_VIEW_BEACON";
export const REFRESH_ADVERTISEMENT_BEACONS = "REFRESH_ADVERTISEMENT_BEACONS";
