import filterOutUnimportantFacets from "./filterOutUnimportantFacets";
import combinations from "combinations";
import getFirstFacetedNavRefineFromFacetCombinations from "./getFirstFacetedNavRefineFromFacetCombinations";

const getBestMatchFacetedRefine = ({ refine, seoImportantFacets }) => {
  if (!refine) {
    return null;
  }

  let facets = refine.split("|");

  facets = filterOutUnimportantFacets({ facets, seoImportantFacets });

  const facetCombinationsToCheck = combinations(facets).sort(
    (a, b) => b.length - a.length
  );

  return (
    getFirstFacetedNavRefineFromFacetCombinations({
      facetCombinationsToCheck,
      seoImportantFacets
    }) || null
  );
};

export default getBestMatchFacetedRefine;
