import shouldRedirect from "./shouldRedirect";
import buildProductUrl from "./buildProductUrl";
import getSearchQuery from "./getSearchQuery";

const getOneProductRedirectUrl = state => {
  if (!shouldRedirect(state)) {
    return null;
  }

  const url = buildProductUrl(state);
  const searchQuery = getSearchQuery(state);

  const queryStringSymbol = url.indexOf("?") > 0 ? "&" : "?";

  return `${url}${queryStringSymbol}SearchQuery=${searchQuery}&SearchRedirect=true`;
};

export default getOneProductRedirectUrl;
