const normalizeFacetValues = facetString => {
  const [facetName, facetValuesString] = facetString.split(":");
  const facetValues = facetValuesString.split(",");
  const normalizedFacetValues = facetValues.sort();
  const normalizedFacetValuesString = normalizedFacetValues.join(",");

  return `${facetName}:${normalizedFacetValuesString}`;
};

export const normalizeRefineParameter = ({ refine }) => {
  if (!refine) {
    return;
  }

  const facets = refine.split("|");

  const normalizedFacets = facets.map(normalizeFacetValues).sort();

  return normalizedFacets.join("|");
};
