import getHeroMedia from "./getHeroMedia";
import { TILE_SIZE_TWO_BY_ONE } from "../constants";

const getUsableHeroMedia = ({
  product,
  productTreatmentDefinition,
  tileSize
}) => {
  const heroMedia = productTreatmentDefinition.media.map(mediaConfig =>
    getHeroMedia({ ...product, mediaConfig })
  );

  if (tileSize === TILE_SIZE_TWO_BY_ONE) {
    return heroMedia.slice(0, 2);
  }

  return [heroMedia[0]];
};

export default getUsableHeroMedia;
