import { createAction } from "redux-actions";
import {
  SET_CATEGORY_ERROR,
  CLEAR_CATEGORY_DETAILS,
  CLEAR_CATEGORY_ERROR,
  CLEAR_CURATED_CATEGORY_TREATMENTS
} from "../constants";

export const setCategoryError = createAction(SET_CATEGORY_ERROR);

export const clearCategoryError = createAction(CLEAR_CATEGORY_ERROR);

export const clearCategoryDetails = createAction(CLEAR_CATEGORY_DETAILS);

export const clearCuratedCategoryTreatments = createAction(
  CLEAR_CURATED_CATEGORY_TREATMENTS
);
