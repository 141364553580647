import styles from "./index.css";
import { useScrollToTopOnMount } from "../hooks";

const BrandsPageSkeleton = () => {
  const items = [...Array(10).keys()];

  useScrollToTopOnMount();

  return (
    <div className={styles.brandsPage}>
      <div className={styles.pageTitleSkeleton} />
      <ul>
        <li className={styles.groupTitleSkeleton} />
        {items.map((_, index) => (
          <li key={index} className={styles.brandTitle}>
            <div className={styles.skeletonText} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BrandsPageSkeleton;
