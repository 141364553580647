import { Component } from "react";
import PropTypes from "prop-types";
import logger from "../../lib/logger";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    error.message = `Main Error Boundary catch: ${error.message}`;
    logger.error(error);
  }

  render() {
    const { children, ErrorComponent } = this.props;
    if (this.state.hasError) {
      return <ErrorComponent />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  ErrorComponent: PropTypes.elementType.isRequired,
  formatLink: PropTypes.func,
  formatTranslation: PropTypes.func
};

export default ErrorBoundary;
