import { handleActions } from "redux-actions";
import getErrorType from "./getErrorType";
import { CLEAR_ERROR, SET_ERROR, ERROR_TYPE } from "./constants";

export const reducer = {
  [CLEAR_ERROR]: () => null,
  [SET_ERROR]: (currentError, { payload }) => {
    const currentErrorIsServerError =
      currentError && currentError.type === ERROR_TYPE.server;
    const { message } = payload;
    const nextError = currentErrorIsServerError ? currentError : { message };

    return {
      type: getErrorType(nextError),
      ...payload
    };
  }
};

export default handleActions(reducer, null);
