import loadable from "@loadable/component";
import ProductListSkeleton from "../../components/Results/ProductListSkeleton";

const SearchPageRoute = loadable(
  () =>
    import(
      /* webpackPreload: true, webpackChunkName: "SEARCH_PAGE" */ "../SearchPage"
    ),
  {
    fallback: <ProductListSkeleton />
  }
);

export default SearchPageRoute;
