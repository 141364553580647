import { CUSTOMER_GUID_PARAM_NAME } from "../../../server/personalisation/constants";
import {
  ADVERTISING_CONSENT_QUERY,
  ADVERTISING_PARTNER_QUERY,
  ADVERTISING_VISITOR_QUERY
} from "../../../state/modules/search/constants";
import {
  SEARCH_QUERY,
  STORE_QUERY,
  LANG_QUERY,
  CURRENCY_QUERY,
  LIMIT_QUERY,
  OFFSET_QUERY,
  SORT_QUERY,
  CHANNEL_QUERY,
  REFINE_QUERY,
  SIZE_SCHEMA_QUERY,
  INCLUDE_GROUPS_QUERY,
  ROW_LENGTH_QUERY,
  COUNTRY_QUERY,
  KEY_STORE_DATA_VERSION_QUERY,
  REGION_QUERY,
  KEY_SEARCH_MVTID_QUERY,
  EXCLUDE_FACETS_QUERY,
  CURATED_CATEGORY_VERSION_QUERY
} from "./constants";

const filterKeys = (obj = {}, allowlist = []) =>
  Object.keys(obj)
    .filter(key => allowlist.includes(key))
    .reduce((a, key) => Object.assign({}, a, { [key]: obj[key] }), {});

const validParams = [
  SEARCH_QUERY,
  STORE_QUERY,
  LANG_QUERY,
  CURRENCY_QUERY,
  LIMIT_QUERY,
  OFFSET_QUERY,
  SORT_QUERY,
  CHANNEL_QUERY,
  REFINE_QUERY,
  SIZE_SCHEMA_QUERY,
  INCLUDE_GROUPS_QUERY,
  ROW_LENGTH_QUERY,
  COUNTRY_QUERY,
  KEY_STORE_DATA_VERSION_QUERY,
  REGION_QUERY,
  KEY_SEARCH_MVTID_QUERY,
  EXCLUDE_FACETS_QUERY,
  CUSTOMER_GUID_PARAM_NAME,
  ADVERTISING_CONSENT_QUERY,
  ADVERTISING_PARTNER_QUERY,
  ADVERTISING_VISITOR_QUERY,
  CURATED_CATEGORY_VERSION_QUERY
];

const sanitiseParams = params => filterKeys(params, validParams);

export default sanitiseParams;
