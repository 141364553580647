export const SHOW_FILTER_OVERLAY = "SHOW_FILTER_OVERLAY";
export const HIDE_FILTER_OVERLAY = "HIDE_FILTER_OVERLAY";
export const UPDATE_VIEWPORT_CATEGORY = "UPDATE_VIEWPORT_CATEGORY";
export const UPDATE_CLIENT_WIDTH = "UPDATE_CLIENT_WIDTH";

export const SMALL_VIEWPORT = "small";
export const MEDIUM_VIEWPORT = "medium";
export const LARGE_VIEWPORT = "large";

export const FOUR_COLUMNS = "fourColumns";
export const THREE_COLUMNS = "threeColumns";
export const TWO_COLUMNS = "twoColumns";
