import { getSanitisedValue } from "./getSanitisedValue";
import { getDistinctBrands } from "./getDistinctBrands";
import { getProductsWithinVicinity } from "./getProductsWithinVicinity";

const setAdSpecificTargeting = (state, products) => {
  const surroundingProducts = getProductsWithinVicinity(products);
  const distinctBrands = getDistinctBrands(surroundingProducts);
  const sanitisedDistinctBrands = distinctBrands.map(getSanitisedValue);

  return {
    ...state,
    adSpecificTargeting: {
      ...state.adSpecificTargeting,
      brand: sanitisedDistinctBrands
    }
  };
};

export default setAdSpecificTargeting;
