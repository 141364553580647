import productsReducers from "./productsReducers";

const receiveProducts = (state, { error, payload: { products } = {} }) => {
  if (error) {
    return state;
  }

  return {
    ...productsReducers.reduce(
      (state, reducer) => reducer(state, products),
      state
    )
  };
};

export default receiveProducts;
