import { sdk } from "@asosteam/asos-web-adverts-sdk";
import sdkProvider from "../sdkProvider";
import getIsProduction from "./getIsProduction";

let advertsSdk = null;

const getAdvertsSdk = async () => {
  if (advertsSdk === null) {
    const featuresDelegatePromise = sdkProvider("features");

    const featuresSdk = await featuresDelegatePromise;
    await featuresSdk.onReady();

    advertsSdk = sdk({
      production: getIsProduction(),
      webContextDelegate: window.asos.webContext.getWebContext(),
      identityDelegatePromise: sdkProvider("identity"),
      featuresDelegatePromise
    });
  }

  return advertsSdk;
};

export default getAdvertsSdk;
