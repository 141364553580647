export const SEARCH_QUERY = "q";
export const STORE_QUERY = "store";
export const LANG_QUERY = "lang";
export const CURRENCY_QUERY = "currency";
export const LIMIT_QUERY = "limit";
export const OFFSET_QUERY = "offset";
export const SORT_QUERY = "sort";
export const CHANNEL_QUERY = "channel";
export const REFINE_QUERY = "refine";
export const SIZE_SCHEMA_QUERY = "sizeSchema";
export const INCLUDE_GROUPS_QUERY = "includeGroups";
export const ROW_LENGTH_QUERY = "rowlength";
export const COUNTRY_QUERY = "country";
export const KEY_STORE_DATA_VERSION_QUERY = "keyStoreDataversion";
export const REGION_QUERY = "region";
export const KEY_SEARCH_MVTID_QUERY = "key-search-mvtid";
export const EXCLUDE_FACETS_QUERY = "excludeFacets";
export const CURATED_CATEGORY_VERSION_QUERY = "tst-category-curated-version";
