const asosKey = "asos";
const plpKey = "plp";

export const config = {
  globalKeys: {
    asosKey,
    webContextKey: "webContext",
    eventBusKey: "eventBus",
    sdkKey: "sdk",
    plpKey,
    storeKey: "_data",
    configKey: "_config",
    siteChromeKey: "siteChrome"
  }
};
