import { ConnectedRouter as Router } from "connected-react-router";
import PropTypes from "prop-types";
import { useNavigtionCapture } from "./useNavigtionCapture";
import setupRouteChangeListener from "./setupRouteChangeListener";
import { useStore } from "react-redux";
import history from "../../client/history";
import { useContext } from "react";
import { decisionsContext } from "../Features";

const ClientRouter = ({ children }) => {
  const store = useStore();
  const decisions = useContext(decisionsContext);

  setupRouteChangeListener(store, decisions);

  useNavigtionCapture(history);

  return <Router history={history}>{children}</Router>;
};

ClientRouter.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired
};

export default ClientRouter;
