import { normalizeRefineParameter } from "./normalizeRefineParameter";

const isSelectedFacet = (facet = { hasSelectedValues: false }) =>
  facet.hasSelectedValues;

export function buildQueryByFacets(facets, priceRange) {
  const selectedFacets = facets.filter(isSelectedFacet);

  const multiSelectQuery = selectedFacets
    .filter(facet => facet.facetType === "TextMultiSelect")
    .map(facet => {
      const selected = facet.facetValues
        .filter(facetValues => facetValues.isSelected === true)
        .map(v => v.id)
        .join(",");

      return `${facet.id}:${selected}`;
    });

  const priceQuery = isSelectedFacet(priceRange)
    ? `currentprice:${priceRange.leftIndicator}<${priceRange.rightIndicator}`
    : false;

  const combinedFacets = []
    .concat(multiSelectQuery, priceQuery)
    .filter(Boolean);

  return combinedFacets.length
    ? normalizeRefineParameter({ refine: combinedFacets.join("|") })
    : undefined;
}

export const buildQueryPriceRange = currentPriceRange => {
  const isRangeInvalid = (min, max) =>
    [0, null, undefined].includes(max, min) || min >= max;

  const buildPriceRange = ({ min, max } = {}) => {
    if (isRangeInvalid(min, max)) {
      return;
    }

    return `${min}-${max}`;
  };

  return buildPriceRange(currentPriceRange);
};

export const getQueryParam = (param, validator, req) => {
  const value = req.query[param];

  return validator(value) ? value : "";
};
