import { createAction } from "redux-actions";
import {
  UPDATE_RANGE_FACET,
  CLEAR_RANGE_FACET,
  SET_RANGE_VALUES,
  UNDO_RANGE_VALUES
} from "../constants";
import { isLargeMediumViewPort } from "../viewportHelper";
import applyFacets from "../facets/apply";
import {
  analyticsRefinementsSelect,
  analyticsAddPendingRefinementSelect
} from "../../analytics/actions";

export const updateRangeFacet = createAction(
  UPDATE_RANGE_FACET,
  (facetId, value) => ({
    facetId,
    value
  })
);

export const setNextPriceRangeToCurrent = createAction(
  SET_RANGE_VALUES,
  values => ({
    values
  })
);

export const undoPriceRange = createAction(UNDO_RANGE_VALUES);

export function changeRangeFacet(facetId, value) {
  return dispatch => {
    dispatch(updateRangeFacet(facetId, value));
  };
}

export function applyRangeFacet(facet) {
  return (dispatch, getState) => {
    const state = getState();
    if (isLargeMediumViewPort(state)) {
      dispatch(applyFacets());
      dispatch(analyticsAddPendingRefinementSelect({ facet }));
    } else {
      dispatch(analyticsRefinementsSelect({ facet }));
    }
  };
}

export const clearRangeFacet = createAction(CLEAR_RANGE_FACET);
