import { handleActions } from "redux-actions";
import { RECEIVE_COLOUR_ENRICHMENT, UPDATE_SEARCH_TERM } from "../constants";
import receiveColourEnrichment from "./receiveColourEnrichment";
import updateSearchTerm from "./updateSearchTerm";

const initialState = { swatches: {}, searchTerm: "" };

export const reducer = handleActions(
  {
    [RECEIVE_COLOUR_ENRICHMENT]: receiveColourEnrichment,
    [UPDATE_SEARCH_TERM]: updateSearchTerm
  },
  initialState
);
