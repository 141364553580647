import config from "../../../../../../../config/common";
import createProductsHash from "./createProductsHash";

const setFingerprintOfPriorProducts = (state, products) => {
  const {
    adverts: { adPosition }
  } = config;
  const fingerprint = createProductsHash(products.slice(0, adPosition));

  return { ...state, priorProductsFingerprint: fingerprint };
};

export default setFingerprintOfPriorProducts;
