import { getQuery } from "../../search/facets/getAllAppliedRefinements";
import isFacetedNavPageHelper from "../../../../helpers/isFacetedNavPage";

const isFacetedNavPage = state => {
  const { category } = state;
  if (!category) {
    return false;
  }

  const {
    details: { seoImportantFacets }
  } = category;
  const { refine } = getQuery(state);

  return isFacetedNavPageHelper({ seoImportantFacets, refine });
};

export default isFacetedNavPage;
