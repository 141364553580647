import {
  analyticsRefinementsDone,
  analyticsRefinementsViewItems
} from "./analyticsSimpleRefinements";
import { MOBILE_DONE, MOBILE_VIEW_ITEMS } from "../../../search/constants";

const analyticsSmallViewSubmit = ({
  facet,
  selectedFacetValues,
  analyticsType
}) => dispatch => {
  if (analyticsType === MOBILE_DONE) {
    dispatch(analyticsRefinementsDone({ facet, selectedFacetValues }));
  } else if (analyticsType === MOBILE_VIEW_ITEMS) {
    dispatch(analyticsRefinementsViewItems({ facet, selectedFacetValues }));
  }
};

export { analyticsSmallViewSubmit as default };
