import getClientConfigValue from "../../../client/getClientConfigValue";

const getSearchBaseUrl = xSiteOrigin => {
  const pathSuffix = "/product/search/";

  if (SERVER) {
    return `${process.env.SEARCH_API_HOSTNAME_TEMPLATE.replace(
      /{xSiteOrigin}/,
      xSiteOrigin
    )}${pathSuffix}`;
  }

  if (getClientConfigValue("LOCAL_DEVELOPMENT") === "true") {
    return `/api${pathSuffix}`;
  }

  return `${getClientConfigValue("BASE_URL")}${pathSuffix}`;
};

export default getSearchBaseUrl;
