import { createAction } from "redux-actions";
import {
  ADD_SAVED_PRODUCT,
  UPDATE_SAVED_PRODUCT,
  REMOVE_SAVED_PRODUCT,
  RECEIVE_SAVED_ITEMS_SUMMARY,
  REMOVE_FROM_SUMMARY
} from "./constants";

export const saveProduct = createAction(
  ADD_SAVED_PRODUCT,
  productId => productId
);

export const updateProduct = createAction(
  UPDATE_SAVED_PRODUCT,
  ({ productId, id }) => ({ productId, id })
);

export const removeSavedProduct = createAction(
  REMOVE_SAVED_PRODUCT,
  productId => productId
);

export const receiveSavedItemsSummary = createAction(
  RECEIVE_SAVED_ITEMS_SUMMARY
);

export const removeFromSummary = createAction(
  REMOVE_FROM_SUMMARY,
  productId => productId
);
