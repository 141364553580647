import { APP, PLACEMENT } from "@asosteam/asos-web-adverts-sdk";
import shouldRefreshBeacons from "./shouldRefreshBeacons";

const getRequestOptions = ({ sponsoredProductIds, isSearchPage, isSorted }) => {
  const options = {
    app: isSearchPage ? APP.SEARCH : APP.CATEGORY,
    placement: PLACEMENT.SPONSORED_PRODUCTS,
    requestDisplayAdverts: true,
    requestSponsoredProducts: false
  };

  if (shouldRefreshBeacons({ sponsoredProductIds, isSorted })) {
    options.requestSponsoredProducts = true;
  }

  return options;
};

export default getRequestOptions;
