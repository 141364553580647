import getBestMatchFacetedRefine from "./getBestMatchFacetedRefine";

const shouldNotReload = {
  shouldReloadCategoryDetails: false,
  refineForCategoryDetails: null
};

const getCategoryDetailsReloadingParameters = ({
  prevLocation,
  nextLocation,
  category
}) => {
  if (CLIENT) {
    const {
      details: { seoImportantFacets }
    } = category;

    if (!seoImportantFacets) {
      return shouldNotReload;
    }

    const prevRefine = new URLSearchParams(prevLocation.search).get("refine");
    const nextRefine = new URLSearchParams(nextLocation.search).get("refine");

    const bestMatchFacetedPrevRefine = getBestMatchFacetedRefine({
      refine: prevRefine,
      seoImportantFacets
    });

    const bestMatchFacetedNextRefine = getBestMatchFacetedRefine({
      refine: nextRefine,
      seoImportantFacets
    });

    if (bestMatchFacetedPrevRefine === bestMatchFacetedNextRefine) {
      return shouldNotReload;
    }

    return {
      shouldReloadCategoryDetails: true,
      refineForCategoryDetails: bestMatchFacetedNextRefine
    };
  }

  if (SERVER) {
    return shouldNotReload;
  }
};

export default getCategoryDetailsReloadingParameters;
