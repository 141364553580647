import config from "../../config/common";
import logger from "../logger";
import packageInfo from "../../../package.json";

let configureRequest, httpsAgent;
if (SERVER) {
  ({ configureRequest } = require("@asosteam/asos-web-base-server"));

  const { HttpsAgent } = require("agentkeepalive");

  const {
    api: {
      keepAlive: { freeSocketTimeout, inactiveSocketTimeout }
    }
  } = config;

  httpsAgent = new HttpsAgent({
    timeout: inactiveSocketTimeout,
    freeSocketTimeout
  });
} else {
  ({ configureRequest } = require("@asosteam/asos-web-request"));
}

const getConfiguredRequest = params =>
  configureRequest({
    client: {
      name: packageInfo.name,
      version: packageInfo.version
    },
    httpsAgent,
    logger,
    ...params
  });

export default getConfiguredRequest;
