const getFiltersString = appliedRefinements =>
  appliedRefinements
    .reduce((acc, { refinementId, selections, min, max }) => {
      if (refinementId === "currentprice") {
        acc.push(`(${refinementId},ge,${min})`);
        acc.push(`(${refinementId},le,${max})`);

        return acc;
      }

      if (refinementId === "brand") {
        selections.forEach(({ selectionId }) => {
          acc.push(`(brand_id,eq,${selectionId})`);
        });

        return acc;
      }

      selections.forEach(({ selectionId }) => {
        acc.push(`(${refinementId},eq,${selectionId})`);
      });

      return acc;
    }, [])
    .join(",");

export default getFiltersString;
