import { compose } from "redux";
import sanitiseParams from "./sanitiseParams";
import makeParamsApiFriendly from "./makeParamsApiFriendly";
import mergeDefaultParams from "./mergeDefaultParams";
import stringifyParams from "./stringifyParams";

const getQueryString = compose(
  stringifyParams,
  mergeDefaultParams,
  makeParamsApiFriendly,
  sanitiseParams
);

export default getQueryString;
