import { clearRangeFacet, setNextPriceRangeToCurrent } from "../actions";
import { updateLocationQuery } from "../../router/actions";
import { getNextPriceRange } from "../priceRangeSelectors";
import { getLocation, getCanonicalPath } from "../../router/selectors";
import { analyticsPendingClearAllRefinements } from "../../analytics/actions";
import { queryStringToObject } from "../../../../helpers/queryStringToObject";

export default function clearAll() {
  return (dispatch, getState) => {
    const initialState = getState();
    const location = getLocation(initialState);
    const currentQuery = queryStringToObject(location.search);

    dispatch(
      analyticsPendingClearAllRefinements({
        facets: initialState.search.facets
      })
    );
    dispatch(clearRangeFacet());
    dispatch(setNextPriceRangeToCurrent(getNextPriceRange(getState())));

    delete currentQuery.refine;
    delete currentQuery.page;

    const {
      query: { cid }
    } = location;

    let pathname;

    if (cid) {
      const stateWithNewQuery = {
        ...initialState,
        router: {
          locationBeforeTransitions: {
            query: currentQuery
          }
        }
      };

      pathname = getCanonicalPath(stateWithNewQuery);
    }

    if (Object.keys(currentQuery).length > 0) {
      dispatch(
        updateLocationQuery({
          query: currentQuery,
          pathname,
          preserve: false
        })
      );
    }
  };
}
