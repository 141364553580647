import { getPages } from "./selectors";

const getProductsForPage = (state, requestedPageNumber) => {
  const [pageEntry] = getPages(state).filter(
    ({ pageNumber }) => pageNumber === requestedPageNumber
  );

  return pageEntry ? pageEntry.products : [];
};

export default getProductsForPage;
