import PropTypes from "prop-types";
import styles from "./index.css";
import classnames from "classnames";

const Icon = ({ icon, className }) => {
  const svg = require(`../icons/${icon}.svg`).default;

  return (
    <span className={classnames(styles.container, className)}>
      <svg viewBox={svg.viewBox}>
        <use href={`#${svg.id}`} />
      </svg>
    </span>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Icon;
