import { handleActions } from "redux-actions";
import {
  CATEGORY_DETAILS_LOADED,
  CATEGORY_LOADED,
  CURATED_CATEGORY_TREATMENTS_LOADED,
  SET_CATEGORY_ERROR,
  CLEAR_CATEGORY_DETAILS,
  CLEAR_CATEGORY_ERROR,
  CLEAR_CURATED_CATEGORY_TREATMENTS
} from "./constants";
import { mapBreadcrumbs } from "./mapBreadcrumbs";

export {
  setCategoryError,
  clearCategoryError,
  clearCuratedCategoryTreatments
} from "./actions";
export {
  getCategoryChildLinks,
  getCategoryDetails,
  getCategoryId,
  getCategoryGender,
  getCategoryTitle,
  getCategoryBreadcrumbs,
  getCategoryError,
  getCategoryHeaderDescription,
  getCategoryIsBrand,
  getCategoryTreatments
} from "./selectors";

const initialState = {};

export default handleActions(
  {
    [CATEGORY_DETAILS_LOADED]: (state, { error, payload }) => {
      if (error) {
        return { ...state };
      }

      return {
        ...state,
        details: payload
      };
    },

    [CATEGORY_LOADED]: (state, { error, payload }) => {
      if (error) {
        return { ...state };
      }

      return {
        ...state,
        isBrand: payload.isBrand,
        gender: payload.gender,
        type: payload.type,
        childLinks: payload.categories,
        breadcrumbs: mapBreadcrumbs(payload.breadcrumb),
        isCurated: payload.isCurated
      };
    },

    [CURATED_CATEGORY_TREATMENTS_LOADED]: (state, { payload }) => ({
      ...state,
      treatments: payload
    }),

    [CLEAR_CATEGORY_DETAILS]: state => ({
      ...state,
      details: {}
    }),

    [SET_CATEGORY_ERROR]: (state, action) => {
      const { status, message = "" } = action.payload;

      return {
        ...state,
        error: {
          status,
          isTimeout: message.includes("timeout")
        }
      };
    },

    [CLEAR_CATEGORY_ERROR]: state => ({
      ...state,
      error: null
    }),

    [CLEAR_CURATED_CATEGORY_TREATMENTS]: state => ({
      ...state,
      treatments: null
    })
  },
  initialState
);
