import sdkProvider from "../sdkProvider";
import config from "../../config/common";
import { receiveSavedItemsSummary } from "../../state/modules/savedItems/actions";

const getSavedItemsSummary = () => async dispatch => {
  const sdk = await sdkProvider("savedItems");

  const { data: items } = await sdk.getItemsSummary({
    requestOptions: { timeout: config.api.savedLists.timeout }
  });

  const savedProducts = items.savedItemIds.map(({ id, productId }) => ({
    id,
    productId
  }));

  dispatch(receiveSavedItemsSummary(savedProducts));
};

export default getSavedItemsSummary;
