import config from "./../../../config/common";

const clamp = ({ lower, clamp, higher }) =>
  Math.max(0, Math.min(Math.max(lower, clamp), higher));

export const extendOffsetLowerBound = state => ({
  ...state.offset,
  lower: clamp({
    lower: state.offset.lower - config.api.product.search.pageSize,
    clamp: 0,
    higher: state.offset.upper
  })
});

export const extendOffsetUpperBound = (state, payload) => ({
  ...state.offset,
  upper: clamp({
    lower: state.offset.upper + config.api.product.search.pageSize,
    clamp: state.offset.lower,
    higher: payload.itemCount
  })
});

const getLowerOffsetFromQuery = (state, payload) => {
  const lowerOffset = (state.query && parseInt(state.query.offset, 10)) || 0;

  return clamp({ lower: lowerOffset, clamp: 0, higher: payload.itemCount });
};

export const getOffsetFromQuery = (state, payload) => {
  const lowerOffset = getLowerOffsetFromQuery(state, payload);
  const offset = {
    initial: lowerOffset,
    lower: lowerOffset,
    upper: lowerOffset
  };

  return extendOffsetUpperBound({ offset }, payload);
};
