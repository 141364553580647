import { getPlatform, getStoreCode, getFloor } from "../../config/selectors";
import { CATEGORY_PAGE } from "../../page/constants";
import { MOBILE, FLOOR_MEN, FLOOR_WOMEN } from "../../config/constants";
import commonConfig from "../../../../config/common";
import { getPageName } from "../../page/selectors";
import { getCategoryGender } from "../../category/selectors";

export const getAdUnitPath = state => {
  const [pageType, floor] =
    getPageName(state) === CATEGORY_PAGE
      ? ["CATEGORY", getCategoryGender(state)]
      : ["SEARCH", getFloor(state)];
  if (![FLOOR_WOMEN, FLOOR_MEN].includes(floor)) {
    return null;
  }

  const {
    adverts: { networkCodeAndEnvPrefix }
  } = commonConfig;

  const platform = getPlatform(state) === MOBILE ? "MWEB" : "WEB";
  const storeCode = getStoreCode(state);
  const gender = floor === FLOOR_MEN ? "MW" : "WW";

  return `/${networkCodeAndEnvPrefix}/${platform}/${storeCode}/${gender}/${pageType}`;
};

export default getAdUnitPath;
