const path = require("path");

const CURRENT_WORKING_DIR = process.cwd();

const appName = "plp";
const appOutput = appName;

module.exports = {
  appName,
  env: {
    browser: "browser",
    server: "server"
  },
  paths: {
    src: path.resolve(CURRENT_WORKING_DIR, "src"),
    assets: path.resolve(CURRENT_WORKING_DIR, "public"),
    compiled: path.resolve(CURRENT_WORKING_DIR, "bin"),
    modules: path.resolve(CURRENT_WORKING_DIR, "node_modules"),
    styles: `${appOutput}/styles`,
    public: "/",
    report: appOutput,
    images: appOutput,
    filename: appOutput,
    chunkfilename: appOutput,
    manifestFilename: appOutput
  },
  output: {
    clientFilename: "main",
    runtimeFilename: "runtime",
    vendorFilename: "vendor"
  }
};
